import * as yup from 'yup';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import CampeignForms from './CampeignForms';

const CampeignFormContainer = ({ apiHandler, editData }) => {
    const numberTransform = (value) => (Number.isNaN(value) ? 0 : value);
    const campeignSchema = yup.object({
        title: yup.string().trim().required('Title is required'),
        campeign_cat: yup
            .array()
            .of(
                yup.object({
                    label: yup.string().trim(),
                    value: yup.string().trim(),
                })
            )
            .required('Campeign Category is required'),
        campeign_date: yup.date().default(new Date()),
        campeign_img: yup.mixed().optional(),
        target_amount: yup
            .number()
            .optional()
            .transform(numberTransform)
            .transform((value) => (value === '' ? null : value)),
        short_description: yup
            .string()
            .trim()
            .max(255, 'Sort Description must be less than 256 characters')
            .optional(),
        long_description: yup.string().optional(),
        status: yup.number().oneOf([1, 0], 'Status must be 1 or 0'),
    });

    const initialValue = {
        title: editData?.title || '',
        campeign_cat:
            editData?.campeign_category?.map((item) => ({
                label: item.title,
                value: item.id,
            })) || [],
        campeign_date: editData?.campeign_date || new Date(),
        target_amount: editData?.target_amount || '',
        campeign_img: editData?.campeign_img || '',
        short_description: editData?.short_description || '',
        long_description: editData?.long_description || '',
        status: editData ? editData?.status : 1,
    };

    const formHandler = async (formData) => {
        const validatedData = await campeignSchema.validate(formData, {
            stripUnknown: true,
        });
        if (apiHandler) {
            validatedData.campeign_cat = validatedData?.campeign_cat?.map((item) => item.value);
            return apiHandler(validatedData);
        }
        return validatedData;
    };
    return (
        <CrudFormLayout
            initialValues={initialValue}
            apiHandler={formHandler}
            buttonCenter
            validationSchema={campeignSchema}
        >
            <CampeignForms />
        </CrudFormLayout>
    );
};

export default CampeignFormContainer;
