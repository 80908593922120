import { FaMinus, FaPlus } from 'react-icons/fa6';
import { Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import ReactDataTable from '../../../ReactDataTable/ReactDataTable';

const FeaturesForm = ({ remove, add }) => {
    const { values } = useFormikContext();
    const column = [
        {
            name: 'Features Name',
            cell: (_, i) => <ReactInput name={`features[${i}].name`} />,
        },
        {
            name: 'Value',
            cell: (_, i) => <ReactInput name={`features[${i}].value`} />,
        },
        {
            name: 'Action',
            width: '120px',
            cell: (_, i) => (
                <div>
                    {i === 0 ? (
                        <Button onClick={() => add({})}>
                            <FaPlus />
                        </Button>
                    ) : (
                        <Button onClick={() => remove()}>
                            <FaMinus />
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div>
            <ReactDataTable columns={column} data={values.features} pagination={false} />
        </div>
    );
};
export default FeaturesForm;
