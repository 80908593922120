import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import CampeignFormContainer from '../../../components/Forms/Websites/Campeigns/CampeignFormContainer';

const AddCampeign = () => {
    const navigate = useNavigate();
    const addCampeignHandler = (formValues) => {
        const formData = formDataConverter(formValues);
        return request.create('campeigns', formData, () => {
            navigate('/dashboard/website/campeigns');
        });
    };

    return (
        <CardLayout title='Add New Campeign'>
            <CampeignFormContainer apiHandler={addCampeignHandler} />
        </CardLayout>
    );
};

export default AddCampeign;
