import { useState } from 'react';
import CardLayout from '../../../components/Layout/CardLayout';
import AddHeroSection from './AddHeroSection';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ModalLayout from '../../../components/Layout/ModalLayout';
import HeroSectionForm from '../../../components/Forms/Websites/HeroSection/HeroSectionForm';
import DeleteModal from '../../../components/Modal/DeleteModal';

const HeroSection = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('hero-section'));

    const columns = [
        {
            name: 'Title',
            center: 'true',
            wrap: true,
            selector: (row) => row?.title,
        },
        {
            name: 'Banner Img',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.banner_img}`}
                    alt='side Img'
                />
            ),
        },
        {
            name: 'Side Img',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.side_img}`}
                    alt='side Img'
                />
            ),
        },
        {
            name: 'Status',
            center: 'true',
            selector: (row) => (row?.status === 1 ? 'Active' : 'InActive'),
        },
        {
            name: 'Action',
            center: 'true',
            cell: (row) => (
                <div>
                    <EditButton
                        onClick={() => {
                            setModalOpen('update');
                            setSelectedItem(row);
                        }}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen('delete');
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const onClose = () => setModalOpen('');
    const refresh = () => setRefresh((prev) => !prev);

    // update handler
    const updateHeroSectionHandler = (formValue) => {
        const formData = new FormData();

        Object.keys(formValue).forEach((key) => {
            formData.append(key, formValue[key]);
        });

        return request.updateOne('hero-section', selectedItem?.id, formData, () => {
            onClose();
            refresh();
        });
    };

    // delete hero section

    const deleteHeroSection = () =>
        request.delete('hero-section', selectedItem?.id, () => {
            onClose();
            refresh();
        });

    return (
        <div>
            <CardLayout
                title='Hero Section '
                buttonTitle='Add New'
                addButtonHandler={() => setModalOpen('create')}
            >
                <ReactDataTable columns={columns} data={result} pending={isLoading} />

                {modalOpen === 'create' && (
                    <AddHeroSection
                        isOpen={modalOpen === 'create'}
                        onClose={onClose}
                        refresh={refresh}
                    />
                )}

                {/* ============ update ========== */}

                {modalOpen === 'update' && (
                    <ModalLayout
                        isOpen={modalOpen === 'update'}
                        onClose={onClose}
                        title='Update Hero Section'
                    >
                        <HeroSectionForm
                            apiHandler={updateHeroSectionHandler}
                            updateData={selectedItem}
                        />
                    </ModalLayout>
                )}

                {/* ============= delete hero section ================ */}

                {modalOpen === 'delete' && (
                    <DeleteModal
                        isOpen={modalOpen === 'delete'}
                        onClose={onClose}
                        handleDelete={deleteHeroSection}
                    />
                )}
            </CardLayout>
        </div>
    );
};
export default HeroSection;
