import { Form } from "react-bootstrap";
import FormLabel from "../../../Text/FormLabel";
import ReactInput from "../../../InputList/ReactInput/ReactInput";
import CrudFormLayout from "../../../Layout/CrudFormLayout";
import StatusCheck from "../../StatusCheck";

const HeroSectionForm = ({ apiHandler, updateData }) => {
  const initialValues = {
    title: updateData?.title || "",
    description: updateData?.description || "",
    banner_img: updateData?.banner_img || "",
    side_img: updateData?.side_img || "",
    button_title: updateData?.button_title || "",
    button_link: updateData?.button_link || "",
    status: updateData ? updateData?.status : 1,
  };
  return (
    <CrudFormLayout initialValues={initialValues} apiHandler={apiHandler}>
      <Form.Group className="mt-3">
        <FormLabel title="Banner Title" required />
        <ReactInput name="title" />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Banner Description" required />
        <ReactInput name="description" />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Banner Images" required />
        <ReactInput name="banner_img" type="file" />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Side Image" required />
        <ReactInput name="side_img" type="file" />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Button Title" required />
        <ReactInput name="button_title" />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Banner Link" required />
        <ReactInput name="button_link" />
      </Form.Group>

      <StatusCheck />
    </CrudFormLayout>
  );
};

export default HeroSectionForm;
