import React from "react";
import { Form } from "react-bootstrap";
import FormLabel from "../../Text/FormLabel";
import ReactInput from "../../InputList/ReactInput/ReactInput";
import StatusCheck from "../StatusCheck";

const CategoryForm = () => (
  <>
    <Form.Group className="mb-3">
      <FormLabel title="Title" required />
      <ReactInput name="title" />
    </Form.Group>

    <Form.Group className="mb-3">
      <FormLabel title="Order" required />
      <ReactInput name="order" />
    </Form.Group>

    <Form.Group className="mb-3">
      <FormLabel title="Info" required />
      <ReactInput name="info" />
    </Form.Group>

    <StatusCheck />
  </>
);

export default CategoryForm;
