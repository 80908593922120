import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import JobFormContainer from '../../../components/Forms/Websites/JobForms/JobFormContainer';
import LoaderLayout from '../../../components/Loader/LoaderLayout';

const EditJob = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { result, isLoading, setRefresh } = useFetch(() => request.getOne('job-list', id), id);

    const editJobHandler = (formValues) =>
        request.updateOne('job-list', id, formValues, () => {
            navigate('/dashboard/website/job-list');
        });

    console.log(result);

    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Edit Job'>
                <JobFormContainer editData={result} apiHandler={editJobHandler} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default EditJob;
