import React from 'react';
import { useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import FormLabel from '../../../../Text/FormLabel';
import ReactInput from '../../../../InputList/ReactInput/ReactInput';
import withMenuItemsData from '../../../../../Pages/Appearance/MenuManagements/HOC/withMenuItemsData';
import ReactSelect from '../../../../ReactSelect/ReactSelect';

const MenuItemForms = ({ children }) => {
    const { values } = useFormikContext();
    // console.log(menuData);
    return (
        <div>
            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <FormLabel title='Menu Level' required />
                        <ReactInput name='menu_level' />
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group>
                        <FormLabel title='URL Slug' required />
                        <ReactInput name='url_slug' />
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group>
                        <FormLabel title='Menu Order' required />
                        <ReactInput name='menu_order' />
                    </Form.Group>
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col sm={6}>
                    <Form.Group>
                        <FormLabel title='Menu Icon' required />
                        <ReactInput name='menu_icon' />
                    </Form.Group>
                </Col>

                <Col sm={6}>{children}</Col>
            </Row>
        </div>
    );
};

export default MenuItemForms;
