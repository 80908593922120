import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import StatusCheck from '../../StatusCheck';

const AudioSurahForm = ({ group, hasAlbum }) => (
    <div>
        <Form.Group>
            <FormLabel title='Surah Name' required />
            <ReactInput name='surah_name' />
        </Form.Group>

        <Form.Group className='mt-3'>
            <FormLabel title='Para No' required />
            <ReactInput name='para_no' />
        </Form.Group>

        <Form.Group className='mt-3'>
            <FormLabel title='Audio File' required />
            <ReactInput name='audio_file' type='file' />
        </Form.Group>

        <Form.Group className='mt-3'>
            <FormLabel title='Reciter name' required />
            <ReactInput name='reciter_name' />
        </Form.Group>

        <Form.Group className='mt-3'>
            <FormLabel title='Description' />
            <ReactInput name='description' as='textarea' rows={3} />
        </Form.Group>

        <Form.Group className='mt-3'>
            <FormLabel title='Order' required />
            <ReactInput name='order' type='number' />
        </Form.Group>

        <StatusCheck />
    </div>
);

export default AudioSurahForm;
