import * as Yup from 'yup';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import PackageForm from './PackageForm';

const PackageFormContainer = ({ apiHandler, editData }) => {
    const initialValues = {
        name: editData?.name || '',
        price: editData?.price || '',
        package_cat_id: editData?.package_cat_id || '',
        duration: parseInt(editData?.duration, 10) || '',
        package_discount: editData?.package_discount || '',
        status: editData?.status === 0 ? 0 : 1 || 1,
        features: editData?.package_features?.map((item) => ({
            name: item.name,
            value: item.value,
        })) || [{ name: '', value: '' }],
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        price: Yup.number()
            .typeError('Price must be a number')
            .required('Price is required')
            .positive('Price must be a positive number'),
        package_cat_id: Yup.string().required('Category ID is required'),
        duration: Yup.string().required('Category ID is required'),
        package_discount: Yup.number()
            .typeError('Discount must be a number')
            .min(0, 'Discount cannot be negative')
            .max(100, 'Discount cannot be more than 100')
            .nullable(),
        status: Yup.number().oneOf([0, 1], 'Status must be either 0 or 1'),
        features: Yup.array().of(
            Yup.object()
                .shape({
                    name: Yup.string().required('Feature name is required'),
                    value: Yup.string(),
                })
                .optional()
        ),
    });

    const formHandler = (values) => {
        if (apiHandler) {
            return apiHandler(values);
        }
        return null;
    };

    return (
        <div>
            <CrudFormLayout
                initialValues={initialValues}
                buttonCenter
                apiHandler={formHandler}
                validationSchema={validationSchema}
            >
                <PackageForm />
            </CrudFormLayout>
        </div>
    );
};

export default PackageFormContainer;
