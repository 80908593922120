import axios from "axios";
import React, { useContext } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContextAPI";

const Signup = () => {
  const navigate = useNavigate();
  const { smsAPI } = useContext(UserContext);

  const handlerOnSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const f_name = form.firstName.value;
    const l_name = "";
    const mobile = form.mobile.value;
    const email = form.email.value;
    const address = form.address.value;
    const password = form.password.value;
    const re_password = form.re_password.value;

    if (password && re_password) {
      if (password === re_password) {
        const patientData = {
          f_name: f_name,
          l_name: l_name,
          mobile: mobile,
          email: email,
          address: address,
          password: password,
        };
        methodCreateUser(patientData, form);
      } else {
        toast.error("Password Not Matched!!");
      }
    }
  };

  const methodCreateUser = async (patientData, form) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/signup`,
      patientData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log(res?.data?.data?.mobile);
    const mobileNumber = res?.data?.data?.mobile;
    const customerName = `${res?.data?.data?.f_name} ${res?.data?.data?.l_name}`;
    const regNo = res?.data?.data?.registration_no;
    const message = `Dear ${customerName}, Your Registration has been Completed Successfully for Syscon Solution ERP. Your registration ID is: ${regNo}. Please Contact our Office to complete the process. Thank You!`;
    if (res.status === 201) {
      smsAPI(mobileNumber, message)
        .then((res) => {
          console.log(res);
          toast.success(`User Created successfully!!`);
          form.reset();
          navigate("/signin");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("User Already Created Using This Email Address!!");
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid">
          <div className="vh-100 d-flex align-items-center justify-content-center">
            <div
              className="card mb-0 shadow d-flex flex-row align-items-center justify-content-center overflow-hidden p-2"
              style={{ width: "70%", height: "75%" }}
            >
              <div className="d-flex gap-0">
                <div className="col-lg-6 d-flex align-items-center">
                  <img src="./register.png" className="img-fluid" alt="" />
                </div>
                <div className="col-lg-6">
                  <div className="card-body pb-3">
                    <h5 className="card-title">Sign Up</h5>
                    <form
                      className="form-body"
                      onSubmit={(e) => handlerOnSubmit(e)}
                    >
                      <div className="login-separater text-center mb-4">
                        <span>SIGN UP WITH EMAIL ADDRESS</span>
                        <hr />
                      </div>
                      <div className="row gap-2">
                        <div className="col-12 ">
                          <label htmlFor="inputName" className="form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                              <i className="bi bi-person-circle" />
                            </div>
                            <input
                              type="text"
                              name="firstName"
                              className="form-control radius-30 ps-5"
                              id="inputName"
                              placeholder="Enter Name"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <label
                            htmlFor="inputEmailAddress"
                            className="form-label"
                          >
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <input
                              type="email"
                              name="email"
                              className="form-control radius-30 ps-5"
                              id="inputEmailAddress"
                              placeholder="Email Address"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="inputEmailAddress"
                            className="form-label"
                          >
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                              <i className="fa-solid fa-phone"></i>
                            </div>
                            <input
                              type="tel"
                              name="mobile"
                              className="form-control radius-30 ps-5"
                              id="inputEmailAddress"
                              placeholder="Mobile Number"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="inputEmailAddress"
                            className="form-label"
                          >
                            Address
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3"></div>
                            <textarea
                              name="address"
                              className="form-control w-100 rounded"
                              rows="3"
                              maxLength="200"
                              placeholder="Home Address"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="inputChoosePassword"
                            className="form-label"
                          >
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                              <i className="bi bi-lock-fill" />
                            </div>
                            <input
                              type="password"
                              name="password"
                              className="form-control radius-30 ps-5"
                              id="inputChoosePassword"
                              placeholder="Enter Password"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="inputChoosePassword"
                            className="form-label"
                          >
                            Re-enter Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                              <i className="bi bi-lock-fill" />
                            </div>
                            <input
                              type="password"
                              name="re_password"
                              className="form-control radius-30 ps-5"
                              id="inputChoosePassword"
                              placeholder="Re-Enter Password"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className="btn btn-primary radius-30"
                            >
                              Sign up
                            </button>
                          </div>
                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-center">
                          <p className="mb-0">
                            Already have an account?{" "}
                            <Link to="/signin"> Sign in here</Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
