import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import MosqueListFormContainer from '../../../components/Forms/Websites/MosqueListForm/MosqueLitFormContainer';

const AddMosqueList = () => {
    const navigate = useNavigate();

    const addMosqueListHandler = (formData) => {
        request.create('mosque-list', formData, () => {
            navigate('/dashboard/website/mosque-list');
        });
    };
    return (
        <CardLayout title='Add Mosque'>
            <MosqueListFormContainer apiHandler={addMosqueListHandler} />
        </CardLayout>
    );
};

export default AddMosqueList;
