import NameInfoForm from "../../../components/Forms/NameInfoForm";
import CategoryForm from "../../../components/Forms/Websites/CategoryForm";
import CrudLayout from "../../../components/Layout/CrudLayout";

const EventCategory = () => {
  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title,
      wrap: true,
      center: "true",
    },
    {
      name: "Info",
      wrap: true,
      center: true,
      selector: (row) => row?.info,
    },
    {
      name: "Order",
      wrap: true,
      center: true,
      selector: (row) => row?.order,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: "true",
      center: "true",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (data) => <span>{data.status === 1 ? "Active" : "Inactive"}</span>,
    },
  ];

  const initialValues = {
    title: "",
    info: "",
    order: "",
    status: 1,
  };

  const config = {
    table_name: "Event Category",
    create_modal_title: "Create New Category",
    update_modal_title: "Update New Category",
    link: "event-category",
    getLink: "event-category",
    columns,
    form: <CategoryForm />,
    initialValues,
  };

  return <CrudLayout config={config} />;
};

export default EventCategory;
