import React from 'react';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';

const DashboardAppearance = () => (
    <MenuItem iconClass='bi bi-file-earmark-person' title='Appearance'>
        <li>
            <Link to='/dashboard/menu-groups'>
                <i className='bi bi-arrow-right-short' />
                Menu Management
            </Link>
        </li>
    </MenuItem>
);

export default DashboardAppearance;
