import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import ReactInput from '../../../../InputList/ReactInput/ReactInput';
import FormLabel from '../../../../Text/FormLabel';
import ReactSelect from '../../../../ReactSelect/ReactSelect';
import { menuForData } from '../../../../../data/staticsData/menuData';

const MenuGroupForm = () => {
    const { setFieldValue, values } = useFormikContext();

    return (
        <Row>
            <Form.Group as={Row}>
                <Col sm={3}>
                    <FormLabel title='Menu Name' required />
                </Col>

                <Col sm={9}>
                    <ReactInput name='menu_name' />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={3}>
                    <FormLabel title='Logo' required />
                </Col>

                <Col sm={9}>
                    <ReactInput name='logo' type='file' />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={3}>
                    <FormLabel title='Menu For' required />
                </Col>

                <Col sm={9}>
                    <ReactSelect
                        data={menuForData}
                        valueName='id'
                        labelName='title'
                        value={values?.menu_for}
                        onChange={(val) => setFieldValue('menu_for', val)}
                    />
                </Col>
            </Form.Group>

            <Form.Group className='my-3 d-flex align-items-center justify-content-end'>
                <div className='d-flex align-items-center justify-content-end'>
                    <Form.Check
                        type='checkbox'
                        name='status'
                        id='flexCheckChecked'
                        checked={values?.status === 1}
                        onChange={(e) => setFieldValue('status', e.target.checked ? 1 : 0)}
                        label='Active'
                    />
                </div>
            </Form.Group>
        </Row>
    );
};

export default MenuGroupForm;
