import React from 'react';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa6';
import { Button } from 'react-bootstrap';
import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import CrudLayout from '../../../components/Layout/CrudLayout';
import NameInfoForm from '../../../components/Forms/NameInfoForm';
import MenuGroupForm from '../../../components/Forms/Appearance/MenuManagements/MenuGroup/MenuGroupForm';

const MenuGroup = () => {
    const columns = [
        {
            name: 'id',
            selector: (_, i) => i + 1,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Menu Name',
            selector: (row) => row?.menu_name,
            sortable: true,
        },
        {
            name: 'Logo',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.logo}`}
                    alt='side Img'
                />
            ),
        },
        {
            name: 'Menu Url',
            selector: (row) => row?.menu_url,
            sortable: 'true',
            center: 'true',
        },
        {
            name: 'Menu For',
            selector: (row) => (row?.menu_for === 1 ? 'Admin' : 'Website'),
            sortable: 'true',
            center: 'true',
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
    ];

    const initialValues = {
        menu_name: '',
        menu_for: '',
        status: 1,
    };

    const config = {
        table_name: 'Menu Group List',
        create_modal_title: 'Add Menu Group',
        update_modal_title: 'Update Menu Group',
        link: 'menu-group',
        columns,
        form: <MenuGroupForm />,
        initialValues,
        detailsButtonWithLink: 'dashboard/menu-groups',
        hasImage: true,
    };

    return <CrudLayout config={config} />;
};

export default MenuGroup;
