import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import PackageFormContainer from '../../../components/Forms/Websites/Package/PackageFormContainer';

const AddPackage = () => {
    const navigate = useNavigate();
    const addPackageHandler = (formValues) =>
        request.create('package-management', formValues, () => {
            navigate('/dashboard/website/packages');
        });

    return (
        <CardLayout title='Add New Package'>
            <PackageFormContainer apiHandler={addPackageHandler} />
        </CardLayout>
    );
};

export default AddPackage;
