import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Button } from 'react-bootstrap';
import CardLayout from '../../../components/Layout/CardLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import LoaderLayout from '../../../components/Loader/LoaderLayout';

const JobRequestList = () => {
    const { id } = useParams();
    const { result, isLoading } = useFetch(() => request.getAllById('job-list/job-request', id));
    console.log(result);
    const downloadFile = (fileName) => {
        const link = `${process.env.REACT_APP_UPLOAD_URL}/website/${fileName}`;

        window.open(link, '_blank');
        // const ancorElement = document.createElement('a');
        // ancorElement.href = link;
        // ancorElement.setAttribute('download', fileName);
        // document.body.appendChild(ancorElement);
        // ancorElement.click();
        //
        // // cleanup
        // document.body.removeChild(ancorElement);
    };
    const columns = [
        {
            name: 'Name',
            center: 'true',
            wrap: true,
            selector: (row) => row?.full_name,
        },
        {
            name: 'Email',
            center: 'true',
            wrap: true,
            selector: (row) => row?.email,
        },
        {
            name: 'Mobile',
            center: 'true',
            wrap: true,
            selector: (row) => row?.phone,
        },
        {
            name: 'Address',
            center: 'true',
            wrap: true,
            selector: (row) => row?.address,
        },
        {
            name: 'Date Of Birth',
            center: 'true',
            wrap: true,
            selector: (row) => row?.date_of_birth && format(row?.date_of_birth, 'dd MMMM yyyy'),
        },
        {
            name: 'Skills',
            center: 'true',
            wrap: true,
            selector: (row) => row?.skills,
        },
        {
            name: 'Technical Skill',
            center: 'true',
            wrap: true,
            selector: (row) => row?.technical_skill,
        },
        {
            name: 'Linkedin',
            center: 'true',
            wrap: true,
            selector: (row) => row?.linkedin,
        },
        {
            name: 'Cover Letter',
            center: 'true',
            wrap: true,
            selector: (row) => row?.cover_letter,
        },
        {
            name: 'Resume',
            center: 'true',
            wrap: true,
            cell: (row) => (
                <Button size='sm' onClick={() => downloadFile(row?.resume)}>
                    Download
                </Button>
            ),
        },
        {
            name: 'Certficate',
            center: 'true',
            wrap: true,
            cell: (row) => (
                <Button size='sm' onClick={() => downloadFile(row?.certificate)}>
                    Download
                </Button>
            ),
        },
    ];
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Job Request'>
                <ReactDataTable data={result?.job_requests} columns={columns} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default JobRequestList;
