import MediaGalleryForm from '../../../components/Forms/Websites/MediaGalleryForm/MediaGalleryForm';
import PackageCategoryForm from '../../../components/Forms/Websites/Package/PackageCategoryForm';
import CrudLayout from '../../../components/Layout/CrudLayout';

const PackageCategoryList = () => {
    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.name,
            sortable: true,
        },
        {
            name: 'Icons',
            wrap: true,
            width: '150px',
            cell: (row) => row.icon,
        },
        {
            name: 'Info',
            wrap: true,
            center: true,
            selector: (row) => row?.info,
        },
        {
            name: 'Multi Time Subscription',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.multi_time_subscription === 1 ? 'YES' : 'NO'}</span>,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
    ];

    const initialValues = {
        name: '',
        icons: '',
        info: '',
        multi_time_subscription: 0,
        status: 1,
    };

    const config = {
        table_name: 'Package Category List',
        create_modal_title: 'Create New Category',
        update_modal_title: 'Update Category',
        link: 'package-category',
        getLink: 'package-category',
        columns,
        form: <PackageCategoryForm />,
        initialValues,
        // hasImage: true,
    };

    return <CrudLayout config={config} />;
};

export default PackageCategoryList;
