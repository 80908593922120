import { Form } from 'react-bootstrap';
import CrudFormLayout from '../../Layout/CrudFormLayout';
import FormLabel from '../../Text/FormLabel';
import ReactInput from '../../InputList/ReactInput/ReactInput';
import StatusCheck from '../StatusCheck';
import { formDataConverter } from '../../../utils/necessaryFunctions';

const StatCounterForm = ({ apiHandler, editData }) => {
    const initialValues = {
        title: editData?.title || '',
        count_number: editData?.count_number || '',
        icon_img: editData?.icon_img || '',
        status: editData ? editData?.status : 1,
    };

    const formHandler = (formValue) => {
        const formData = formDataConverter(formValue);
        if (apiHandler) {
            return apiHandler(formData);
        }
        return null;
    };
    return (
        <CrudFormLayout initialValues={initialValues} apiHandler={formHandler}>
            <Form.Group className='mt-3'>
                <FormLabel title='Title' required />

                <ReactInput name='title' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Count Number' required />

                <ReactInput name='count_number' />
            </Form.Group>

            <Form.Group className='mt-3'>
                <FormLabel title='Icon Image' required />

                <ReactInput name='icon_img' type='file' />
            </Form.Group>

            {/* <Form.Group className='mt-3'> */}
            {/*     <FormLabel title='Description' /> */}
            {/**/}
            {/*     <ReactInput name='description' as='textarea' rows={6} /> */}
            {/* </Form.Group> */}

            <StatusCheck />
        </CrudFormLayout>
    );
};

export default StatCounterForm;
