import { useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';
import ReactSelect from '../../../ReactSelect/ReactSelect';
import ReactDatePicker from '../../../ReactDatePicker/ReactDatePicker';
import FormLabel from '../../../Text/FormLabel';
import ReactTextEditor from '../../../Editors/ReactTextEditor';
import StatusCheck from '../../StatusCheck';

const JobForms = () => {
    const { values, setFieldValue } = useFormikContext();

    const { result: gender } = useFetch(() => request.getAll('gender'));

    const { result: country } = useFetch(() => request.getAll('country'));

    return (
        <div>
            <Row>
                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Job Title' />
                    <ReactInput name='title' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Job Image' />
                    <ReactInput name='job_img' type='file' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Company Name' />
                    <ReactInput name='company_name' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Job Type' />
                    <ReactInput name='job_type' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Category' />
                    <ReactInput name='category' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Job Tag(separet with comma)' />
                    <ReactInput name='job_tag' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Location' />
                    <ReactSelect
                        data={country}
                        labelName='name'
                        valueName='id'
                        value={values.location}
                        onChange={(val) => setFieldValue('location', val)}
                        searchable
                    />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Offered Salary' />
                    <ReactInput name='offered_salary' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='CTC' />
                    <ReactInput name='ctc' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Experience' />
                    <ReactInput name='experience' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Gender' />
                    <ReactSelect
                        data={gender}
                        value={values.gender}
                        onChange={(val) => setFieldValue('gender', val)}
                    />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Industry' />
                    <ReactInput name='industry' />
                </Col>

                <Col sm={12} md={6} className='mt-3'>
                    <FormLabel title='Application End' />
                    <ReactDatePicker
                        value={values.application_end}
                        onChange={(val) => setFieldValue('application_end', val)}
                    />
                </Col>
            </Row>

            <Row>
                <Form.Group className='mt-3'>
                    <FormLabel title='Job Description' />
                    <ReactTextEditor
                        defaultData={values.job_description}
                        setEditorData={(val) => setFieldValue('job_description', val)}
                    />
                </Form.Group>
            </Row>

            <Row>
                <StatusCheck />
            </Row>
        </div>
    );
};

export default JobForms;
