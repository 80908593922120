const eventDateType = [
    {
        label: 'Single Date',
        value: 'single',
        dateFormat: 'MMMM d, yyyy h:mm aa',
    },
    {
        label: 'Daily',
        value: 'daily',
        dateFormat: 'h:mm aa',
    },
    {
        label: 'Weekly Date',
        value: 'weekly',
        dateFormat: 'eeee h:mm aa',
    },
    {
        label: 'Monthly Date',
        value: 'monthly',
        dateFormat: 'dd h:mm aa',
    },
];

export default eventDateType;
