import React from 'react';
import * as Yup from 'yup';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import MosqueListForm from './MosqueListForm';

const MosqueListFormContainer = ({ apiHandler, editData }) => {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Mosque name is required'),
        address: Yup.string().required('Address is required'),
        masjid_id: Yup.string().required('Masjid ID is required'),
        // fajr: Yup.date().required('Fajr prayer time is required'),
        // dhuhr: Yup.date().required('Dhuhr prayer time is required'),
        // asr: Yup.date().required('Asr prayer time is required'),
        // maghrib: Yup.date().required('Maghrib prayer time is required'),
        // isha: Yup.date().required('Isha prayer time is required'),
        // jummah: Yup.date().required('Jummah prayer time is required'),
        status: Yup.number().required('Status is required'),
        order: Yup.number().required('Order is required'),
    });

    console.log(editData);

    const initialValues = {
        name: editData?.name || '',
        address: editData?.address || '',
        masjid_id: editData?.masjid_id || '',
        // fajr: editData?.fajr || new Date(), // Current time
        // dhuhr: editData?.dhuhr || new Date(),
        // asr: editData?.asr || new Date(),
        // maghrib: editData?.maghrib || new Date(),
        // isha: editData?.isha || new Date(),
        // jummah: editData?.jummah || new Date(),
        status: editData ? editData?.status : 1,
        order: editData ? editData?.order : '',
    };

    const handleFormSubmit = async (formValues) => {
        const validateData = await validationSchema.validate(formValues, {
            stripUnknown: true,
        });

        console.log(validateData);

        if (apiHandler) {
            return apiHandler(validateData);
        }
        return validateData;
    };
    return (
        <CrudFormLayout
            initialValues={initialValues}
            apiHandler={handleFormSubmit}
            validationSchema={validationSchema}
            buttonCenter
        >
            <MosqueListForm />
        </CrudFormLayout>
    );
};

export default MosqueListFormContainer;
