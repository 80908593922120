import HeroSectionForm from "../../../components/Forms/Websites/HeroSection/HeroSectionForm";
import ModalLayout from "../../../components/Layout/ModalLayout";
import request from "../../../request/request";
import { formDataConverter } from "../../../utils/necessaryFunctions";

const AddHeroSection = ({ onClose, isOpen, refresh }) => {
  const createNewHeroSection = (formValue) => {
    console.log(formValue);

    const formData = formDataConverter(formValue);

    request.create("hero-section", formData, () => {
      onClose();
      refresh();
    });
    return null;
  };

  return (
    <ModalLayout
      title="Add New Hero Section"
      onClose={onClose}
      isOpen={isOpen}
      size="lg"
    >
      <HeroSectionForm apiHandler={createNewHeroSection} />
    </ModalLayout>
  );
};

export default AddHeroSection;
