import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';

const websiteSections = [
    { title: 'Hero Section', path: '/dashboard/website/hero-section' },
    { title: 'About Us', path: '/dashboard/website/about-us' },
    { title: 'Our Service', path: '/dashboard/website/our-service' },
    { title: 'Destination', path: '/dashboard/website/destination' },
    { title: 'Stat Counter', path: '/dashboard/website/stat-counter' },
    { title: 'Blog Category List', path: '/dashboard/website/blog-category' },
    { title: 'Blog List', path: '/dashboard/website/blogs' },
    { title: 'Associates', path: '/dashboard/website/associates' },
    { title: 'Certificate', path: '/dashboard/website/certificate' },
    { title: 'Awards', path: '/dashboard/website/awards' },
    { title: 'Photo Gallery', path: '/dashboard/website/photo-gallery' },
    { title: 'Video Gallery', path: '/dashboard/website/video-gallery' },
    { title: 'Footer', path: '/dashboard/website/footer' },
    { title: 'Job List', path: '/dashboard/website/job-list' },
    { title: 'Contact Msg List', path: '/dashboard/website/contact-message' },
    { title: 'Team Members List', path: '/dashboard/website/team-members' },
    { title: 'CEO Message List', path: '/dashboard/website/ceo-message' },
    {
        title: 'Mission, Vision & Motto',
        path: '/dashboard/website/mission-vision-motto',
    },
    { title: 'Pages', path: '/dashboard/website/pages' },
    { title: 'Mosque List', path: '/dashboard/website/mosque-list' },
    { title: 'Event Category', path: '/dashboard/website/event-category' },
    { title: 'Event List', path: '/dashboard/website/events' },
    {
        title: 'Package Category',
        path: '/dashboard/website/package-category',
    },
    {
        title: 'Package ',
        path: '/dashboard/website/packages',
    },
    {
        title: 'Course Category ',
        path: '/dashboard/website/course-category',
    },
    {
        title: 'Course List ',
        path: '/dashboard/website/courses',
    },
    {
        title: 'Course Registration List ',
        path: '/dashboard/website/courses-registration',
    },
    {
        title: 'Campeign Category ',
        path: '/dashboard/website/campeign-category',
    },
    {
        title: 'Campeign List ',
        path: '/dashboard/website/campeigns',
    },
    {
        title: 'Album List ',
        path: '/dashboard/website/album',
    },
    {
        title: 'Surah List ',
        path: '/dashboard/website/audio-surah',
    },
];
const DashboardWebsiteSection = () => (
    <div>
        <MenuItem iconClass='bi bi-file-earmark-person' title='Websites'>
            {websiteSections.map((section) => (
                <li key={section.title}>
                    <Link to={section.path}>
                        <i className='bi bi-arrow-right-short' />
                        {section.title}
                    </Link>
                </li>
            ))}
        </MenuItem>
    </div>
);

export default DashboardWebsiteSection;
