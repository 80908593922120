import React, { Fragment, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';
import { ErrorMessage, useField } from 'formik';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ModalLayout from '../../Layout/ModalLayout';
import NameInfoForm from '../../Forms/NameInfoForm';
import CrudFormLayout from '../../Layout/CrudFormLayout';
import ReactTooltip from '../../Tooltips/ReactTooltip';
import ReactSelect from '../../ReactSelect/ReactSelect';

const SelectCity = ({ ...props }) => {
    const { result: countryList, setRefresh } = useFetch(() => request.getAll('country'));
    const [isModalOpen, setModalOpen] = useState(false);
    const [field, meta, helper] = useField(props);

    // const initialValues = {
    //     name: '',
    //     info: '',
    //     status: 1,
    // };
    // const handleFormSubmit = async (formData) => {
    //     await request.create('department', formData, () => {
    //         setModalOpen(false);
    //         setRefresh((prevState) => !prevState);
    //     });
    // };

    // console.log(countryList);

    return (
        <>
            <InputGroup>
                <ReactSelect
                    data={countryList}
                    value={field?.value}
                    valueName='id'
                    labelName='name'
                    onChange={(val) => helper.setValue(val)}
                />

                {/* <ReactTooltip title='Add New Department'> */}
                {/*    <Button variant='teal' onClick={() => setModalOpen(true)}> */}
                {/*        <FaPlus /> */}
                {/*    </Button> */}
                {/* </ReactTooltip> */}
            </InputGroup>

            <ErrorMessage name={props?.name} component='p' className='error' />

            {/* {isModalOpen && ( */}
            {/*    <ModalLayout */}
            {/*        title='Add Department' */}
            {/*        isOpen={isModalOpen} */}
            {/*        onClose={() => setModalOpen(false)} */}
            {/*    > */}
            {/*        <CrudFormLayout initialValues={initialValues} apiHandler={handleFormSubmit}> */}
            {/*            <NameInfoForm firstFieldName='name' /> */}
            {/*        </CrudFormLayout> */}
            {/*    </ModalLayout> */}
            {/* )} */}
        </>
    );
};

export default SelectCity;
