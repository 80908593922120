import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import AddNewMenuItems from './AddNewMenuItems';
import MenuItemsListShow from './MenuItemsListShow';

const MenuItems = () => {
    const { id } = useParams();
    const { result, isLoading, setRefresh } = useFetch(
        () => request.getAll(`menu-items/main-menu/${id}`),
        id
    );

    console.log(result);

    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Menu Items'>
                <Row>
                    <Col sm={12} md={6}>
                        <MenuItemsListShow menuData={result} setRefresh={setRefresh} />
                    </Col>

                    <Col sm={12} md={6}>
                        <AddNewMenuItems setRefresh={setRefresh} menuData={result} />
                    </Col>
                </Row>
            </CardLayout>
        </LoaderLayout>
    );
};

export default MenuItems;
