import { useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import ReactTextEditor from '../../../Editors/ReactTextEditor';
import StatusCheck from '../../StatusCheck';
import ReactDatePicker from '../../../ReactDatePicker/ReactDatePicker';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';
import ReactMultiSelect from '../../../ReactSelect/ReactMultiSelect';
import ReactSelect from '../../../ReactSelect/ReactSelect';
import ErrorMsg from '../../../Text/ErrorMsg';
import eventDateType from '../../../../data/eventDateType';
import eventTimeData from '../../../../data/eventTime';

const EventForms = () => {
    const { values, setFieldValue } = useFormikContext();
    const { result } = useFetch(() => request.getAll('event-category/getData/active'));

    // Default date format if no match found
    const defaultDateFormat = 'dd h:mm aa';

    // Determine dateFormat based on values.date_type
    const dateFormat =
        eventDateType.find((item) => item.value === values?.date_type)?.dateFormat ||
        'MMMM d, yyyy h:mm aa';

    return (
        <Row>
            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Title' required />
                    <ReactInput name='title' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Event Category' required />
                    <ReactMultiSelect
                        data={result}
                        labelName='title'
                        valueName='id'
                        value={values?.event_cat}
                        defaultValue={values?.event_cat}
                        onChange={(val) => setFieldValue('event_cat', val)}
                        // onChange={(val) => console.log(val)}
                        isMulti
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Event Date type' required />
                    <ReactSelect
                        data={eventDateType}
                        labelName='label'
                        valueName='value'
                        value={values?.date_type}
                        onChange={(val) => setFieldValue('date_type', val)}
                        // onChange={(val) => setFieldValue('date_type', val?.value)}
                    />
                </Form.Group>
                <ErrorMsg name='date_type' />
            </Col>

            <Col sm={12} md={6}>
                {values?.date_type !== 'daily' && (
                    <Form.Group className='mt-3'>
                        <FormLabel title='Date' required />
                        <ReactDatePicker
                            showTimeSelect
                            timeFormat='h:mm aa'
                            dateFormat={dateFormat}
                            showWeekNumbers={values.date_type === 'weekly'}
                            value={values?.event_date}
                            onChange={(val) => setFieldValue('event_date', new Date(val))}
                        />
                    </Form.Group>
                )}
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Date Time' required />
                    <ReactInput name='event_date_time' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Event Image' required />
                    <ReactInput name='event_img' type='file' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Location' required />
                    <ReactInput name='location' as='textarea' row={3} />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Order' required />
                    <ReactInput name='order' type='number' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Short Description' required />
                    <ReactInput name='short_description' as='textarea' row={3} />
                </Form.Group>
            </Col>

            <Col sm={12}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Long Description' required />
                    <ReactTextEditor
                        defaultData={values?.description}
                        setEditorData={(val) => setFieldValue('description', val)}
                    />
                </Form.Group>
            </Col>

            <div>
                <StatusCheck />
            </div>
        </Row>
    );
};

export default EventForms;
