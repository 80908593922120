import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import FormLabel from '../../../Text/FormLabel';
// import ReactDatePicker from '../../../ReactDatePicker/ReactDatePicker';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import StatusCheck from '../../StatusCheck';

// const salatTimes = [
//     { name: 'Fajr', key: 'fajr' },
//     { name: 'Dhuhr', key: 'dhuhr' },
//     { name: 'Asr', key: 'asr' },
//     { name: 'Maghrib', key: 'maghrib' },
//     { name: 'Isha', key: 'isha' },
//     { name: 'Jummah', key: 'jummah' },
// ];

const MosqueListForm = () => (
    // const { setFieldValue, values } = useFormikContext();

    <>
        <Row>
            <h4 className='mb-3'>Mosque Details</h4>
            <Col sm={12} md={6} className='mt-3'>
                <Form.Group as={Row}>
                    <Col sm={12} md={4}>
                        <FormLabel title='Mosque Name' required />
                    </Col>

                    <Col sm={12} md={8}>
                        <ReactInput name='name' placeholder='Mosque Name' />
                    </Col>
                </Form.Group>
            </Col>

            <Col sm={12} md={6} className='mt-3'>
                <Form.Group as={Row}>
                    <Col sm={12} md={4}>
                        <FormLabel title='Address' required />
                    </Col>

                    <Col sm={12} md={8}>
                        <ReactInput name='address' placeholder='Address' />
                    </Col>
                </Form.Group>
            </Col>

            <Col sm={12} md={6} className='mt-3'>
                <Form.Group as={Row}>
                    <Col sm={12} md={4}>
                        <FormLabel title='Masjid ID' required />
                    </Col>

                    <Col sm={12} md={8}>
                        <ReactInput name='masjid_id' placeholder='masjidal ID' />
                    </Col>
                </Form.Group>
            </Col>

            <Col sm={12} md={6} className='mt-3'>
                <Form.Group as={Row}>
                    <Col sm={12} md={4}>
                        <FormLabel title='Order' required />
                    </Col>

                    <Col sm={12} md={8}>
                        <ReactInput name='order' />
                    </Col>
                </Form.Group>
            </Col>
        </Row>

        {/* <Row> */}
        {/*     <h4 className='my-3'>Iqmah Times</h4> */}
        {/**/}
        {/*     {salatTimes.map((salat) => ( */}
        {/*         <Col sm={12} md={6} key={salat.key} className='mt-3'> */}
        {/*             <Form.Group as={Row}> */}
        {/*                 <Col sm={12} md={4}> */}
        {/*                     <FormLabel title={salat.name} required /> */}
        {/*                 </Col> */}
        {/*                 <Col> */}
        {/*                     <ReactDatePicker */}
        {/*                         value={values[salat.key]} // Access value using key */}
        {/*                         onChange={(date) => setFieldValue(salat.key, date)} */}
        {/*                         showTimeSelect */}
        {/*                         showTimeSelectOnly */}
        {/*                         timeIntervals={1} */}
        {/*                         timeCaption='Time' */}
        {/*                         dateFormat='h:mm aa' */}
        {/*                     /> */}
        {/*                 </Col> */}
        {/*             </Form.Group> */}
        {/*         </Col> */}
        {/*     ))} */}
        {/* </Row> */}

        <StatusCheck />
    </>
);
export default MosqueListForm;
