import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import JobFormContainer from '../../../components/Forms/Websites/JobForms/JobFormContainer';
import request from '../../../request/request';

const AddJob = () => {
    const navigate = useNavigate();

    const addJobList = async (formValues) => {
        try {
            return request.create('job-list', formValues, () => {
                navigate('/dashboard/website/job-list');
            });
        } catch (err) {
            return err;
        }
    };

    return (
        <CardLayout title='Add Job'>
            <JobFormContainer apiHandler={addJobList} />
        </CardLayout>
    );
};

export default AddJob;
