import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import useFetch from '../../../hooks/useFetch';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import CampeignFormContainer from '../../../components/Forms/Websites/Campeigns/CampeignFormContainer';

const UpdateCampeigns = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { result, isLoading } = useFetch(() => request.getOne('campeigns', id));

    const UpdateAboutUsHandler = (formValue) => {
        const formData = formDataConverter(formValue);

        return request.updateOne('campeigns', id, formData, () => {
            navigate('/dashboard/website/campeigns');
        });
    };
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Campeigns'>
                <CampeignFormContainer apiHandler={UpdateAboutUsHandler} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateCampeigns;
