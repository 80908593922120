import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../Context/UserContextAPI';

const Signin = () => {
    const { methodSignin, settingData } = useContext(UserContext);
    const navigate = useNavigate();

    const handlerOnSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const email = form.email.value;
        const password = form.password.value;
        const rememberMe = form.rememberMe.checked; // Check if "Remember Me" is checked

        console.log(rememberMe);
        // console.log(phoneNumber, password);
        if (email && password) {
            const signinData = {
                email,
                password,
                rememberMe, // Include rememberMe flag
            };
            methodSignin(signinData)
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        const userData = res.data;
                        // console.log(userData);
                        window.localStorage.setItem('jwtToken', userData.token);
                        navigate('/dashboard');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    return (
        <>
            {/* Signin Page Container */}
            <div className='wrapper'>
                {/* <!--start content--> */}
                <div className='container-fluid'>
                    <div className='vh-100 d-flex align-items-center justify-content-center'>
                        <div
                            className='card mb-0 shadow d-flex flex-row align-items-center justify-content-center overflow-hidden p-2'
                            style={{ width: '70%', height: '75%' }}
                        >
                            <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                                <img src='/img/erp-banner.png' className='img-fluid' alt='' />
                            </div>
                            <div className='col-lg-6'>
                                <div className='card-body p-4 p-sm-5'>
                                    <div className='w-100'>
                                        <h5 className='card-title'>Sign In</h5>
                                        <p className='card-text'>
                                            Please enter a valid email address and password.
                                        </p>
                                    </div>
                                    <form
                                        className='form-body'
                                        onSubmit={(e) => handlerOnSubmit(e)}
                                    >
                                        <div className='login-separater text-center'>
                                            {' '}
                                            <span>SIGN IN WITH EMAIL</span>
                                            <hr />
                                        </div>
                                        <div className='row gap-2'>
                                            <div className='col-12'>
                                                <label
                                                    htmlFor='inputEmailAddress'
                                                    className='form-label'
                                                >
                                                    Email Address
                                                </label>
                                                <div className='ms-auto position-relative'>
                                                    <div className='position-absolute top-50 translate-middle-y search-icon px-3'>
                                                        <i className='fa-solid fa-phone' />
                                                    </div>
                                                    <input
                                                        type='text'
                                                        name='email'
                                                        className='form-control radius-30 ps-5'
                                                        id='inputEmailAddress'
                                                        placeholder='Enter Email Address'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <label
                                                    htmlFor='inputChoosePassword'
                                                    className='form-label'
                                                >
                                                    Password
                                                </label>
                                                <div className='ms-auto position-relative'>
                                                    <div className='position-absolute top-50 translate-middle-y search-icon px-3'>
                                                        <i className='bi bi-lock-fill' />
                                                    </div>
                                                    <input
                                                        type='password'
                                                        name='password'
                                                        className='form-control radius-30 ps-5'
                                                        id='inputChoosePassword'
                                                        placeholder='Enter Password'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between my-2'>
                                                <div className='form-check form-switch d-flex align-items-center'>
                                                    <input
                                                        className='form-check-input me-2'
                                                        type='checkbox'
                                                        // id='flexSwitchCheckChecked'
                                                        // checked={checked}
                                                        name='rememberMe'
                                                        // onChange={handleCheck}
                                                    />
                                                    <label className='form-check-label'>
                                                        Remember Me
                                                    </label>
                                                </div>
                                                <div className='text-end'>
                                                    <Link to='/forget-password'>
                                                        Forgot Password ?
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className='col-12 text-center'>
                                                <div className='d-grid'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary radius-30'
                                                    >
                                                        Sign In
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <p> */}
                                            {/*     Don't have an account yet?{' '} */}
                                            {/*     <Link to='/signup'>Sign up here</Link> */}
                                            {/* </p> */}
                                        </div>
                                    </form>
                                </div>
                                <img
                                    src={`${process.env.REACT_APP_UPLOAD_URL}/${settingData?.logo_image}`}
                                    width='150px'
                                    height='150px'
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        width: '120px',
                                        objectFit: 'contain',
                                        marginTop: '0px' /* Adjust margin top as needed */,
                                    }}
                                    alt=''
                                    className=''
                                />
                                <p className='text-end'>
                                    Powered By{' '}
                                    <Link to='https://dhakaapps.com/' target='_blank'>
                                        DHAKA APPS{' '}
                                    </Link>{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signin;
