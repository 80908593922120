import { useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import FormLabel from '../../../Text/FormLabel';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import ReactTextEditor from '../../../Editors/ReactTextEditor';
import StatusCheck from '../../StatusCheck';
import ReactDatePicker from '../../../ReactDatePicker/ReactDatePicker';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';
import ReactMultiSelect from '../../../ReactSelect/ReactMultiSelect';
import ErrorMsg from '../../../Text/ErrorMsg';

const CampeignForms = () => {
    const { values, setFieldValue } = useFormikContext();
    const { result } = useFetch(() => request.getAll('campeign-category/getData/active'));

    // console.log(values);

    return (
        <Row>
            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Title' required />
                    <ReactInput name='title' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Campeign Category' required />
                    <ReactMultiSelect
                        data={result}
                        labelName='title'
                        valueName='id'
                        value={values?.campeign_cat}
                        defaultValue={values?.campeign_cat}
                        onChange={(val) =>
                            setFieldValue(
                                'campeign_cat',
                                val?.map((item) => item)
                            )
                        }
                        // onChange={(val) => console.log(val)}
                        isMulti
                    />
                </Form.Group>
                <ErrorMsg name='campeign_cat' />
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Date' required />
                    <ReactDatePicker
                        value={values?.campeign_date}
                        onChange={(val) => setFieldValue('campeign_date', val)}
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Campeign Image' required />
                    <ReactInput name='campeign_img' type='file' />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Short Description' required />
                    <ReactInput name='short_description' as='textarea' row={3} />
                </Form.Group>
            </Col>

            <Col sm={12} md={6}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Target Amount' />
                    <ReactInput name='target_amount' />
                </Form.Group>
            </Col>

            <Col sm={12}>
                <Form.Group className='mt-3'>
                    <FormLabel title='Long Description' required />
                    <ReactTextEditor
                        defaultData={values?.long_description}
                        setEditorData={(val) => setFieldValue('long_description', val)}
                    />
                </Form.Group>
            </Col>

            <div>
                <StatusCheck />
            </div>
        </Row>
    );
};

export default CampeignForms;
