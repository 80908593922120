import { useNavigate } from "react-router-dom";
import CardLayout from "../../../components/Layout/CardLayout";
import request from "../../../request/request";
import { formDataConverter } from "../../../utils/necessaryFunctions";
import EventFormContainer from "../../../components/Forms/Websites/Events/EventsFormContainer";

const AddEvents = () => {
  const navigate = useNavigate();
  const addEventHandler = (formValues) => {
    console.log(formValues);
    const formData = formDataConverter(formValues);
    return request.create("events", formData, () => {
      navigate("/dashboard/website/events");
    });
  };

  return (
    <CardLayout title="Add New Event">
      <EventFormContainer apiHandler={addEventHandler} />
    </CardLayout>
  );
};

export default AddEvents;
