// this func returns keys with values arguments
import currency from 'currency.js';
import format from 'date-fns/format';
import calculate from './calculate';

export default function getKeyByValue(obj, value) {
    const keys = Object.keys(obj);
    const foundKey = keys.find((key) => obj[key] === value);
    return foundKey || null; // Return null if the value is not found
}

export const generateUniqueNumber = () => Math.floor(100000 + Math.random() * 900000).toString();

export const discountGenerator = (discount, total) => {
    if (typeof discount === 'string' && discount.endsWith('%')) {
        // Remove '%' and convert to a number
        const discountNumber = parseFloat(discount.replace('%', '')) / 100;

        const totalDiscount = total * discountNumber;
        return total - totalDiscount;
    }
    return total - parseFloat(discount || '0');
};

// this function calculates the discount . if the discount is 10 then it will return 10, but if its percentage is 10 then it will return percentage value of total value;
export function calculateDiscount(discount, totalValue) {
    // Use regular expression to match numeric values

    if (discount === null || discount === undefined) {
        return 0;
    }

    // Convert inputValue to string
    const inputString = String(discount);

    // Use regular expression to match numeric values
    const numericMatch = inputString.match(/\d+(\.\d+)?/);
    // console.log(numericMatch);

    if (numericMatch) {
        // Extracted numeric value
        const discountPercentage = parseFloat(numericMatch[0]);

        if (!Number.isNaN(discountPercentage)) {
            // Calculate the discount amount

            if (inputString.endsWith('%')) {
                // return ((discountPercentage / 100) * totalValue).toFixed(2);
                return calculate.multiply(totalValue, discountPercentage / 100);
            }
            return parseFloat(inputString);
        }
    }

    // If no valid discount percentage is found, return 0
    return 0;
}

export const checkPercentageAndDivided = (value, total) => {
    if (typeof value === 'string' && value.endsWith('%')) {
        // Remove '%' and convert to a number
        const integerValue = parseFloat(value.replace('%', '')) / 100;
        return (total * integerValue).toFixed(2);
    }
    return value;
};

// purchases products sub value calculations
export const calculateSubTotalValue = (cost, quantity, discount) => {
    // const totalCost = cost * (quantity || 1);
    const totalCost = calculate.multiply(cost, quantity);
    const discountedValue = calculateDiscount(discount, totalCost);
    return calculate.sub(totalCost, discountedValue);
};

// form Data Converter

export const formDataConverter = (values) => {
    const formData = new FormData();

    Object.keys(values).forEach((data) => {
        formData.append(data, values[data]);
    });
    return formData;
};

/**
 * Formats a time string in HH:MM:SS format to 12-hour AM/PM format.
 *
 * @param {string} timeString The time string in HH:MM:SS format (e.g., "05:24:00").
 * @returns {string} The formatted time in 12-hour AM/PM format (e.g., "5:24 AM").
 *
 * @throws {ValueError} If the time string is not in the expected format.
 */
export function formatTimeToAMPM(timeString) {
    if (!timeString || !/^\d{2}:\d{2}:\d{2}$/.test(timeString)) {
        throw new Error('Invalid time format. Please use HH:MM:SS.');
    }

    const hours = parseInt(timeString.split(':')[0]);
    const minutes = parseInt(timeString.split(':')[1]);
    const seconds = parseInt(timeString.split(':')[2]);

    // Create a Date object with the extracted values (set the year, month, and day to arbitrary values)
    const formattedTime = format(new Date(2024, 0, 1, hours, minutes, seconds), 'h:mm a');

    return formattedTime;
}
