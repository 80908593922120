import { formDataConverter } from '../../../../utils/necessaryFunctions';
import CrudFormLayout from '../../../Layout/CrudFormLayout';

import JobForms from './JobForms';

const JobFormContainer = ({ apiHandler, editData }) => {
    const initialValues = {
        gender: '',

        title: '',

        company_name: '',

        job_type: '',

        category: '',

        job_tag: '',

        location: 18,

        offered_salary: '',

        ctc: '',

        experience: '',

        industry: '',

        application_end: new Date(),

        job_description: '',
        status: 1,
    };

    const formSubmitHandler = (formValues) => {
        console.log(formValues);
        const formData = formDataConverter(formValues);
        if (apiHandler) {
            return apiHandler(formData);
        }

        return null;
    };

    return (
        <CrudFormLayout
            initialValues={editData || initialValues}
            buttonCenter
            apiHandler={formSubmitHandler}
        >
            <JobForms />
        </CrudFormLayout>
    );
};

export default JobFormContainer;
