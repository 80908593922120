import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";

const departmentUrl = `${process.env.REACT_APP_API_BASE_URL}/department`;

const Department = () => {
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [editModalData, setEditModalData] = useState({});
    const [deleteModalData, setDeleteModalData] = useState({});
    const [isChecked, setIsChecked] = useState(true);

    const handlerOnEditFormSubmit = (e) => {
        e.preventDefault();

        const form = e.target;
        const name = form.name.value;
        const info = form.info.value;
        const status = form.status.value;
        const originStatus = status === "true" ? 1 : 0;

        const originData = {
            name: name,
            info: info,
            status: originStatus,
        };
        console.log(originData);

        methodUpdateParentCat(originData);
    };

    const methodUpdateParentCat = async (originData) => {
        const res = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/department/${editModalData.id}`,
            originData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        console.log(res);
        if (res?.status === 200 && res?.statusText === "OK") {
            toast.success("Department Updated successfully!!");
        } else {
            toast.error(res?.data?.message);
        }
        fetchData();
    };

    const handlerOnDelete = async () => {
        const res = await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/department/${deleteModalData.id}`
        );
        // console.log(res);
        if (res.status === 200) {
            toast.success("Department deleted successfully!!");
        }
        fetchData();
    };

    const handlerOnCreateFormSubmit = (e) => {
        e.preventDefault();

        const form = e.target;
        const name = form.name.value;
        const info = form.info.value;
        const status = form.status.value;
        const originStatus = status === "true" ? 1 : 0;

        const originData = {
            name: name,
            info: info,
            status: originStatus,
        };
        form.reset();

        methodCreateDepartment(originData);
    };

    const methodCreateDepartment = async (originData) => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/department`,
            originData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        console.log(res);
        if (res?.data?.status === "OK") {
            toast.success("Department created successfully!!");
        } else {
            toast.error(res?.data?.message);
        }
        fetchData();
    };

    const fetchData = () => {
        axios
            .get(departmentUrl)
            .then((response) => {
                const updatedData = response.data.data.map((item) => {
                    return {
                        ...item,
                        status: item.status === 1 ? "active" : "inactive",
                    };
                });
                setData(updatedData);
            })
            .catch((err) => console.log(err));
    };

    const customStyles = {
        headRow: {
            style: {
                fontSize: "15px", // Increase the font size of header titles
                border: "1px solid #e0e0e0", // Add a border to the cells
                fontWeight: "bold",
                backgroundColor: "#0d6efd",
                color: "white",
            },
        },
        headCells: {
            style: {
                border: "1px solid #e0e0e0", // Add a border to every cell of the head row
                overflowWrap: "break-word", // Wrap and break text to the next line
            },
        },
        cells: {
            style: {
                fontSize: "14px", // Adjust the font Department of cell content as needed
                border: "1px solid #e0e0e0", // Add a border to the cells
                padding: "4px",
                whiteSpace: "pre-wrap", // Set white space to pre-wrap for all cells
            },
        },
    };

    const config = {
        page_size: 10,
        show_filter: true,
        show_length_menu: true,
        show_pagination: true,
        pagination: "advance",
        length_menu: [10, 50, 100],
        button: {
            excel: true,
            print: true,
            extra: true,
        },
    };

    console.log(data);

    useEffect(() => {
        fetchData();
        const timeout = setTimeout(() => {
            setPending(false);
        }, 400);
        return () => clearTimeout(timeout);
    }, []);

    const columns = [
        {
            name: "Department Name",
            selector: (row) => row?.name,
            sortable: true,
        },
        {
            name: "Info",
            selector: (row) => row?.info,
            sortable: "true",
            center: "true",
        },
        {
            name: "Status",
            selector: (row) => row?.status,
            sortable: "true",
            center: "true",
        },
        {
            name: "Action",
            sortable: "false",
            center: "true",
            cell: (data) => {
                return (
                    <>
                        {/* Button trigger modal */}
                        <button
                            type="button"
                            onClick={() => {
                                setEditModalData(data);
                                if (data.status === 1) {
                                    setIsChecked(true);
                                } else {
                                    setIsChecked(false);
                                }
                            }}
                            className="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target={`#editDepart-${data.id}`}
                            style={{ marginRight: "5px" }}
                        >
                            <i className="fa fa-edit"></i>
                        </button>
                        <div
                            className="modal fade"
                            id={`editDepart-${data.id}`}
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Edit Department
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <form onSubmit={(e) => handlerOnEditFormSubmit(e)}>
                                        <div className="modal-body">
                                            <div className="row mb-3">
                                                <label className="col-sm-3 col-form-label">
                                                    Department Name
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-sm-9">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        defaultValue={data?.name}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-sm-3 col-form-label">Info</label>
                                                <div className="col-sm-9">
                          <textarea
                              name="info"
                              defaultValue={data?.info}
                              className="form-control w-100"
                              rows="3"
                              maxLength="200"
                          ></textarea>
                                                </div>
                                            </div>
                                            <div className="row mb-3 d-flex align-items-center justify-content-end">
                                                <div className="col-sm-9">
                                                    <div className="col-sm-12">
                                                        <div
                                                            className="d-flex align-items-center justify-content-end"
                                                            defaultChecked={data?.status === 1 ? true : false}
                                                        >
                                                            <input
                                                                className="form-check-input mt-0 me-2"
                                                                type="checkbox"
                                                                defaultChecked={
                                                                    data?.status === 1 ? true : false
                                                                }
                                                                name="status"
                                                                value={isChecked}
                                                                onChange={() => setIsChecked(!isChecked)}
                                                                id={`flexCheckChecked-${data?.id}`}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`flexCheckChecked-${data?.id}`}
                                                            >
                                                                Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Update changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={() => setDeleteModalData(data)}
                            className="btn btn-danger btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target={`#deleteDepart-${data.id}`}
                            style={{ marginRight: "5px" }}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                        <div
                            className="modal fade"
                            id={`deleteDepart-${data.id}`}
                            tabIndex={-1}
                            style={{ display: "none" }}
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Delete Department</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body pb-0">
                                        <div className="mb-3 row ">
                                            <div className="col-sm-10">
                                                <p>Are you sure you want to delete this department?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => handlerOnDelete(deleteModalData)}
                                            data-bs-dismiss="modal"
                                        >
                                            Yes
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box d-flex">
                            <h6 className="mb-0 text-uppercase">Department List</h6>
                            <div className="col">
                                <button
                                    type="button"
                                    onClick={() => setIsChecked(true)}
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#createAttributeSizeModal"
                                >
                                    <i className="fa-solid fa-plus"></i> Add New
                                </button>
                            </div>
                        </div>
                        <hr />
                        <DataTable
                            columns={columns}
                            data={data}
                            config={config}
                            highlightOnHover
                            striped
                            dense
                            progressPending={pending}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="createAttributeSizeModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Create Department
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <form onSubmit={(e) => handlerOnCreateFormSubmit(e)}>
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label">
                                        Department Name
                                        <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label">Info</label>
                                    <div className="col-sm-9">
                    <textarea
                        name="info"
                        defaultValue={data?.info}
                        className="form-control w-100"
                        rows="3"
                        maxLength="200"
                    ></textarea>
                                    </div>
                                </div>
                                <div className="row mb-3 d-flex align-items-center justify-content-end">
                                    <div className="col-sm-9">
                                        <div className="col-sm-12">
                                            <div className=" d-flex align-items-center  justify-content-end">
                                                <input
                                                    className="form-check-input mt-0 me-2"
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    name="status"
                                                    value={isChecked}
                                                    onChange={() => setIsChecked(!isChecked)}
                                                    id="flexCheckChecked"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckChecked"
                                                >
                                                    Active
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Save changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Department;