import { format } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteButton from '../../../components/Button/DeleteButton';
import EditButton from '../../../components/Button/EditButton';
import CardLayout from '../../../components/Layout/CardLayout';
import DeleteModal from '../../../components/Modal/DeleteModal';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';

const MosqueList = () => {
    const navigate = useNavigate();
    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('mosque-list'));

    // const { result: api } = useFetch(() =>
    //     axios.get(
    //         'https://api.aladhan.com/v1/timings/17-07-2007?latitude=51.508515&longitude=-0.1254872&method=2'
    //     )
    // );
    //
    // console.log('api', api);

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const columns = [
        {
            name: 'Name',
            wrap: true,
            center: 'true',
            cell: (row) => row?.name,
        },
        {
            name: 'Address',
            wrap: true,
            center: 'true',
            cell: (row) => row?.address,
        },
        {
            name: 'Mosjid ID',
            wrap: true,
            center: 'true',
            cell: (row) => row?.masjid_id,
        },
        // {
        //     name: 'Fajr',
        //     wrap: true,
        //     center: 'true',
        //     cell: (row) => row?.fajr && format(new Date(row.fajr), 'h:mm a'),
        // },
        // {
        //     name: 'Dhuhr',
        //     wrap: true,
        //     center: 'true',
        //     cell: (row) => row.dhuhr && format(new Date(row.dhuhr), 'h:mm a'),
        // },
        // {
        //     name: 'Asr',
        //     wrap: true,
        //     center: 'true',
        //     cell: (row) => row.asr && format(new Date(row.asr), 'h:mm a'),
        // },
        // {
        //     name: 'Maghrib',
        //     wrap: true,
        //     center: 'true',
        //     cell: (row) => row.maghrib && format(new Date(row.maghrib), 'h:mm a'),
        // },
        // {
        //     name: 'Ishar',
        //     wrap: true,
        //     center: 'true',
        //     cell: (row) => row.isha && format(new Date(row.isha), 'h:mm a'),
        // },
        //
        // {
        //     name: 'Jummah',
        //     wrap: true,
        //     center: 'true',
        //     cell: (row) => row.jummah && format(new Date(row.jummah), 'h:mm a'),
        // },
        {
            name: 'Order',
            wrap: true,
            center: 'true',
            cell: (row) => row?.order,
        },
        {
            name: 'Status',
            wrap: true,
            center: 'true',
            cell: (row) => (row.status === 1 ? 'Active' : 'Inactive'),
        },
        {
            name: 'Action',
            wrap: true,
            center: 'true',
            cell: (row) => (
                <div>
                    <EditButton
                        onClick={() => navigate(`/dashboard/website/mosque-list/edit/${row.id}`)}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen(true);
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    // delete function
    const deleteJobDataHandler = () =>
        request.delete('mosque-list', selectedItem.id, () => {
            setModalOpen(false);
            setSelectedItem(null);
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Mosque List'
            buttonTitle='Add Mosque'
            addButtonHandler={() => navigate('/dashboard/website/mosque-list/add')}
        >
            <ReactDataTable data={result} columns={columns} />

            {isModalOpen && (
                <DeleteModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedItem(null);
                    }}
                    handleDelete={deleteJobDataHandler}
                />
            )}
        </CardLayout>
    );
};

export default MosqueList;
