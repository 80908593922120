import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DeleteModal = ({ isOpen, onClose, handleDelete }) => (
    <Modal show={isOpen} onHide={onClose} centered animation>
        <Modal.Header closeButton style={{ border: 'none' }} />
        <Modal.Body className='text-center p-0'>
            <div>
                <i className='bi bi-exclamation-circle text-warning' style={{ fontSize: '70px' }} />
            </div>
            <h4> Are you sure?</h4>
            <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
            <Button variant='danger' onClick={handleDelete}>
                Yes, delete it
            </Button>
            <Button variant='secondary' onClick={onClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
);

export default DeleteModal;

