import MediaGalleryForm from '../../../components/Forms/Websites/MediaGalleryForm/MediaGalleryForm';
import CrudLayout from '../../../components/Layout/CrudLayout';
import AudioSurahForm from '../../../components/Forms/Websites/audioSurah/AudioSurahForm';

const AudioSurah = () => {
    const columns = [
        {
            name: 'Surah Name',
            selector: (row) => row?.surah_name,
            center: 'true',
            wrap: true,
            // sortable: true,
        },
        {
            name: 'Para No',
            selector: (row) => row?.para_no,
            sortable: true,
            center: 'true',
            wrap: true,
        },
        {
            name: 'Audio File',
            wrap: true,
            width: '150px',
            cell: (row) => (
                <audio controls>
                    <source
                        src={`${process.env.REACT_APP_UPLOAD_URL}/website/audio/${row?.audio_file}`}
                        type='audio/mpeg'
                    />
                    <track kind='captions' label='English' src='' srcLang='en' default />
                </audio>
            ),
        },
        {
            name: 'Description',
            wrap: true,
            center: true,
            selector: (row) => row?.description,
        },
        {
            name: 'Order',
            wrap: true,
            center: true,
            selector: (row) => row?.order,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row?.status,
            sortable: 'true',
            center: 'true',
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (data) => <span>{data.status === 1 ? 'Active' : 'Inactive'}</span>,
        },
    ];

    const initialValues = {
        surah_name: '',
        para_no: '',
        order: '',
        audio_file: '',
        reciter_name: '',
        description: '',
        status: 1,
    };

    const config = {
        table_name: 'Audio Surah',
        create_modal_title: 'Create Audio Surah',
        update_modal_title: 'Update Audio Surah',
        link: 'audio-surah',
        getLink: 'audio-surah',
        columns,
        form: <AudioSurahForm />,
        initialValues,
        hasImage: true,
    };

    return <CrudLayout config={config} />;
};

export default AudioSurah;
