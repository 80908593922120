import React, { useState } from 'react';
import MenuItemsFormContainer from '../../../components/Forms/Appearance/MenuManagements/MenuItems/MenuItemsFormContainer';
import ModalLayout from '../../../components/Layout/ModalLayout';
import request from '../../../request/request';

const MenuItemsListShow = ({ menuData, setRefresh }) => {
    const [isMenuClicked, setMenuClicked] = useState(false);

    const [clickedMenuData, setMenuClickedData] = useState({});

    const editMenuItemHandler = (formData) =>
        // console.log(formData);
        request.updateOne('menu-items', clickedMenuData?.id, formData, () => {
            setRefresh((prev) => !prev);
            setMenuClicked(false);
        });
    const deleteMenuHandler = () =>
        request.delete('menu-items', clickedMenuData?.id, () => {
            setMenuClicked(false);
            setRefresh((prev) => !prev);
        });

    return (
        <div>
            {menuData?.length > 0 &&
                menuData.map((menu) => (
                    <div key={menu?.id}>
                        <button
                            type='button'
                            onClick={() => {
                                setMenuClicked(true);
                                setMenuClickedData(menu);
                            }}
                            className='border p-2 rounded m-0 mt-3 bg-white text-start'
                            style={{ width: '90%' }}
                        >
                            {menu.menu_level}
                        </button>
                        {menu?.subMenuItems.length > 0 &&
                            menu?.subMenuItems.map((sub) => (
                                <button
                                    type='button'
                                    onClick={() => {
                                        setMenuClicked(true);
                                        setMenuClickedData(sub);
                                    }}
                                    className='border p-2 rounded m-0 mt-3 bg-white text-start ms-5'
                                    key={sub?.id}
                                    style={{ width: '90%' }}
                                >
                                    {sub.menu_level}
                                </button>
                            ))}
                    </div>
                ))}

            {/* Modal for update delete menu  */}

            {isMenuClicked && (
                <ModalLayout isOpen={isMenuClicked} onClose={() => setMenuClicked(false)} size='lg'>
                    <MenuItemsFormContainer
                        menuData={menuData}
                        clickedMenuData={clickedMenuData}
                        onClose={() => setMenuClicked(false)}
                        apiHandler={editMenuItemHandler}
                        deleteMenuHandler={deleteMenuHandler}
                    />
                </ModalLayout>
            )}
        </div>
    );
};

export default MenuItemsListShow;
