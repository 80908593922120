import * as yup from 'yup';
import CrudFormLayout from '../../../Layout/CrudFormLayout';
import EventForms from './EventForms';

const EventFormContainer = ({ apiHandler, editData }) => {
    const eventSchema = yup.object({
        title: yup.string().trim().required('Title is required'),
        event_cat: yup.array().required('Event Category is required'),
        event_date: yup.date().default(new Date()),
        event_img: yup.mixed().optional(),
        date_type: yup.string().required('Date Type is required'),
        event_date_time: yup.string().required('Event Time is required'),
        short_description: yup
            .string()
            .trim()
            .max(300, 'Sort Description must be less than 256 characters')
            .optional()
            .nullable(),
        location: yup.string().required('Location is required'),
        order: yup.number().required('Order is required'),
        description: yup.string().optional(),
        status: yup.number().oneOf([1, 0], 'Status must be 1 or 0'),
    });

    const initialValue = {
        title: editData?.title || '',
        event_cat:
            editData?.event_category?.map((item) => ({
                label: item.title,
                value: item.id,
            })) || '',
        event_date: editData?.event_date || new Date(),
        location: editData?.location || '',
        order: editData?.order || '',
        event_img: editData?.event_img || '',
        description: editData?.description || '',
        status: editData ? editData?.status : 1,
        date_type: editData?.date_type || '',
        event_date_time: editData?.event_date_time || '',
        short_description: editData?.short_description || '',
    };

    const formHandler = async (formData) => {
        formData.event_cat = formData.event_cat.map((item) => item.value);

        const validatedData = await eventSchema.validate(formData, {
            stripUnknown: true,
        });

        if (apiHandler) {
            return apiHandler(validatedData);
        }
        return validatedData;
    };
    return (
        <CrudFormLayout
            initialValues={initialValue}
            apiHandler={formHandler}
            buttonCenter
            validationSchema={eventSchema}
        >
            <EventForms />
        </CrudFormLayout>
    );
};

export default EventFormContainer;
