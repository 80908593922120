import React from 'react';
import MenuItemsFormContainer from '../../../components/Forms/Appearance/MenuManagements/MenuItems/MenuItemsFormContainer';
import request from '../../../request/request';
import withMenuItemsData from './HOC/withMenuItemsData';

const AddNewMenuItems = ({ setRefresh, menuData }) => {
    const createMenuItems = (formData, resetForm) =>
        request.create('menu-items', formData, () => {
            setRefresh((prev) => !prev);
            resetForm();
        });

    return (
        <div>
            <MenuItemsFormContainer apiHandler={createMenuItems} menuData={menuData} />
        </div>
    );
};

export default AddNewMenuItems;
