const menuForData = [
    {
        id: 1,
        title: 'Admin',
    },
    {
        id: 2,
        title: 'Websites',
    },
];

// eslint-disable-next-line import/prefer-default-export
export { menuForData };
