import React from 'react';
import { Form } from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import { useFormikContext } from 'formik';
import ReactInput from '../../../InputList/ReactInput/ReactInput';

const PackageCategoryForm = () => (
    <div>
        <Form.Group className='mt-3'>
            <FormCheckLabel title='Name' required />
            <ReactInput name='name' placeholder='Enter Name' />
        </Form.Group>

        <Form.Group className='mt-3'>
            <FormCheckLabel title='Icon' />
            <ReactInput name='icon' placeholder='Enter Icon class ' />
        </Form.Group>

        <Form.Group className='mt-3'>
            <FormCheckLabel title='Info' />
            <ReactInput name='info' placeholder='Enter Info' />
        </Form.Group>

        <Form.Group className='mt-3 d-flex gap-3 justify-content-end'>
            <ReactInput
                type='checkbox'
                name='multi_time_subscription'
                label='Multi Time Subscription'
            />
            <ReactInput type='checkbox' name='status' label='Active' />
        </Form.Group>
    </div>
);

export default PackageCategoryForm;
