import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../../Context/UserContextAPI';
import Navbar from '../Navbar/Navbar';
import './DashboardLayout.scss';
import MenuItem from '../DashboardContents/MenuItem';
import DashboardAppearance from '../DashboardContents/DashboardAppearance';
import DashboardWebsiteSection from '../DashboardContents/DashboardWebsiteSection';

const DashboardLayout = () => {
    const { accessPerm } = useContext(UserContext);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarHovered, setSidebarHovered] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const [activeRoles, setActiveRoles] = useState([]);
    const getData = () => {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/settings`)
            .then((response) => {
                const allData = response.data.data[0];
                setData(allData);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAllActiveRoles = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/roles/active/findAll`
            );
            setActiveRoles(data?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
        (async function () {
            await getAllActiveRoles();
        })();
        const token = window.localStorage.getItem('jwtToken');
        if (!token) {
            navigate('/signin');
        }
    }, []);

    const handleToggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };

    return (
        <div className='dashboard-layout'>
            <div className='wrapper'>
                <Navbar />
            </div>
            <div className={`wrapper ${sidebarCollapsed ? 'toggled' : ''}`}>
                <aside
                    className={`sidebar-wrapper ${sidebarCollapsed ? 'toggled' : ''} ${
                        sidebarHovered ? 'sidebar-hovered' : ''
                    }`}
                    data-simplebar='true'
                    onMouseEnter={() => setSidebarHovered(true)}
                    onMouseLeave={() => setSidebarHovered(false)}
                >
                    <Link to='/dashboard'>
                        <div onClick={handleToggleSidebar} className='sidebar-header'>
                            <div>
                                {data?.logo_image ? (
                                    <div>
                                        {sidebarCollapsed ? (
                                            <img
                                                src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.fav_image}`}
                                                className=''
                                                alt='logo icon'
                                                style={{ height: '50px', width: '100%' }}
                                            />
                                        ) : (
                                            <img
                                                src={`${process.env.REACT_APP_UPLOAD_URL}/${data?.logo_image}`}
                                                className=''
                                                alt='logo icon'
                                                style={{ height: '50px', width: '100%' }}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    // <img src={`http://localhost:5000/api/${data?.logo_image}`} alt="" />
                                    `${data?.website_name}`
                                )}
                            </div>
                            <div className='toggle-icon ms-auto'>
                                <i className='bi bi-chevron-double-left' />
                            </div>
                        </div>
                    </Link>

                    <ul className='metismenu' id='menu'>
                        {/* ------------------------------- appearance -------------------------------*/}

                        <DashboardAppearance />

                        {/* ------------------------------------- websites sections -----------------*/}

                        <DashboardWebsiteSection />

                        {/* ------------------------------- user management --------------------------------*/}

                        {(accessPerm(5, 4) ||
                            accessPerm(4, 4) ||
                            accessPerm(2, 4) ||
                            accessPerm(3, 4) ||
                            accessPerm(3, 4) ||
                            accessPerm(45, 1)) && (
                            <MenuItem iconClass='bi bi-people' title='User Management'>
                                {accessPerm(5, 4) && (
                                    <li>
                                        <Link to='/dashboard/users'>
                                            <i className='bi bi-arrow-right-short' />
                                            Users
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(5, 4) &&
                                    activeRoles &&
                                    activeRoles.map((role) => (
                                        <li key={role?.id}>
                                            <Link to={`/dashboard/user/roles/${role?.id}`}>
                                                <i className='bi bi-arrow-right-short' />
                                                {role?.name}
                                            </Link>
                                        </li>
                                    ))}
                                {accessPerm(4, 4) && (
                                    <li>
                                        <Link to='/dashboard/roles'>
                                            <i className='bi bi-arrow-right-short' />
                                            Roles
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(2, 4) && (
                                    <li>
                                        <Link to='/dashboard/activity'>
                                            <i className='bi bi-arrow-right-short' />
                                            Activities
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(3, 4) && (
                                    <li>
                                        <Link to='/dashboard/module'>
                                            <i className='bi bi-arrow-right-short' />
                                            Modules
                                        </Link>
                                    </li>
                                )}

                                {accessPerm(6, 2) && (
                                    <li>
                                        <Link to='/dashboard/user-management/role-permissions'>
                                            <i className='bi bi-arrow-right-short' />
                                            Role Permissions
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(6, 2) && (
                                    <li>
                                        <Link to='/dashboard/user-management/terms-conditions'>
                                            <i className='bi bi-arrow-right-short' />
                                            Terms & Conditions
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(6, 2) && (
                                    <li>
                                        <Link to='/dashboard/user-management/service-category'>
                                            <i className='bi bi-arrow-right-short' />
                                            Service Category
                                        </Link>
                                    </li>
                                )}

                                <li>
                                    <Link to='/dashboard/user-management/service-category-list'>
                                        <i className='bi bi-arrow-right-short' />
                                        Service List
                                    </Link>
                                </li>
                                {accessPerm(45, 1) && (
                                    <li>
                                        <Link to='/dashboard/upload-donor'>
                                            <i className='bi bi-arrow-right-short' />
                                            Upload Donor
                                        </Link>
                                    </li>
                                )}
                            </MenuItem>
                        )}
                        {(accessPerm(7, 4) ||
                            accessPerm(8, 4) ||
                            accessPerm(9, 4) ||
                            accessPerm(10, 4) ||
                            accessPerm(11, 4) ||
                            accessPerm(12, 4) ||
                            accessPerm(13, 4) ||
                            accessPerm(14, 4) ||
                            accessPerm(15, 4) ||
                            accessPerm(16, 4) ||
                            accessPerm(17, 4) ||
                            accessPerm(18, 4) ||
                            accessPerm(19, 4) ||
                            accessPerm(20, 4) ||
                            accessPerm(1, 2)) && (
                            <MenuItem iconClass='bi bi-gear' title='Settings'>
                                {accessPerm(7, 4) && (
                                    <li>
                                        <Link to='/dashboard/static-content-groups'>
                                            <i className='bi bi-arrow-right-short' />
                                            Static Content Groups
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(8, 4) && (
                                    <li>
                                        <Link to='/dashboard/static-content'>
                                            <i className='bi bi-arrow-right-short' />
                                            Static Contents
                                        </Link>
                                    </li>
                                )}

                                {accessPerm(9, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/instruments-category'>
                                            <i className='bi bi-arrow-right-short' />
                                            Instruments Category
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(10, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/instruments'>
                                            <i className='bi bi-arrow-right-short' />
                                            Instruments
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <Link to='/dashboard/settings/department'>
                                        <i className='bi bi-arrow-right-short' />
                                        Department
                                    </Link>
                                </li>
                                {accessPerm(13, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/room-management'>
                                            <i className='bi bi-arrow-right-short' />
                                            Room Management
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(14, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/gender'>
                                            <i className='bi bi-arrow-right-short' />
                                            Gender
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(15, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/uom'>
                                            <i className='bi bi-arrow-right-short' />
                                            Unit of Measurements
                                        </Link>
                                    </li>
                                )}

                                {accessPerm(16, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/professions'>
                                            <i className='bi bi-arrow-right-short' />
                                            Professions
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(17, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/merital-status'>
                                            <i className='bi bi-arrow-right-short' />
                                            Marital Status
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(18, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/country'>
                                            <i className='bi bi-arrow-right-short' />
                                            Country
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(19, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/state'>
                                            <i className='bi bi-arrow-right-short' />
                                            State
                                        </Link>
                                    </li>
                                )}
                                {accessPerm(20, 4) && (
                                    <li>
                                        <Link to='/dashboard/settings/city'>
                                            <i className='bi bi-arrow-right-short' />
                                            City
                                        </Link>
                                    </li>
                                )}

                                {accessPerm(1, 2) && (
                                    <li>
                                        <Link to='/dashboard/settings/system-settings'>
                                            <i className='bi bi-arrow-right-short' />
                                            System Settings
                                        </Link>
                                    </li>
                                )}
                            </MenuItem>
                        )}
                    </ul>
                </aside>

                <main className='page-content'>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default DashboardLayout;
