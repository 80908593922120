import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import CollectionLabel from '../Pages/CollectionLabel/CollectionLabel';
import ForgetPasswordPage from '../Pages/ForgetPasswordPage/ForgetPasswordPage';
import OtpVerification from '../Pages/OtpVerification/OtpVerification';
import ProfilePage from '../Pages/ProfilePage/ProfilePage';
import ResetPasswordPage from '../Pages/ResetPasswordPage/ResetPasswordPage';
import City from '../Pages/Settings/City/City';
import Country from '../Pages/Settings/Country/Country';
import GenderPage from '../Pages/Settings/GenderPage/GenderPage';
import MaritialStatusSettings from '../Pages/Settings/MaritalStatus/MaritalStatusSettings';
import ProfessionSettingsPage from '../Pages/Settings/ProfessionSettingsPage/ProfessionSettingsPage';
import Room from '../Pages/Settings/RoomManagement/Room';
import SettingsPage from '../Pages/Settings/SettingsPage/SettingsPage';
import State from '../Pages/Settings/State/State';
import StaticContentGroupPage from '../Pages/Settings/StaticContentGroupPage/StaticContentGroupPage';
import StaticContentPage from '../Pages/Settings/StaticContentPage/StaticContentPage';
import UOMPage from '../Pages/Settings/UOMPage/UOMPage';
import Signin from '../Pages/Signin/Signin';
import Signup from '../Pages/Signup/Signup';
import ActivityListPage from '../Pages/UserManagement/ActivityListPage/ActivityListPage';
import ModuleListPage from '../Pages/UserManagement/ModuleListPage/ModuleListPage';
import RoleListPage from '../Pages/UserManagement/RoleListPage/RoleListPage';
import RolePermissionPage from '../Pages/UserManagement/RolePermissionPage/RolePermissionPage';
import ServiceCategory from '../Pages/UserManagement/ServiceCategory/ServiceCategory';
import TermsConditions from '../Pages/UserManagement/TermsConditions/TermsConditions';
import UserListPage from '../Pages/UserManagement/UserListPage/AllUsers/UserListPage';
import DashboardLayout from '../components/Layout/DashboardLayout';
import PrivateRouter from './Private/PrivateRouter';
import Department from '../Pages/Settings/Department/Department';
import UserByRoles from '../Pages/UserManagement/UserListPage/RoleWiseUsers/UserByRoles';
import InstrumentCategoryList from '../Pages/Settings/InstrumentCategoryList/InstrumentCategoryList';
import InstrumentsPage from '../Pages/Settings/InstrumentsPage/InstrumentsPage';
import HomePage from '../Pages/HomePage/HomePage';
import MenuGroup from '../Pages/Appearance/MenuManagements/MenuGroup';
import MenuItems from '../Pages/Appearance/MenuManagements/MenuItems';
import HeroSection from '../Pages/WebsitePannel/HeroSection/HeroSection';
import AddHeroSection from '../Pages/WebsitePannel/HeroSection/AddHeroSection';
import AboutUs from '../Pages/WebsitePannel/aboutUs/AboutUs';
import AddAboutUs from '../Pages/WebsitePannel/aboutUs/AddAboutUs';
import UpdateAboutUs from '../Pages/WebsitePannel/aboutUs/UpdateAboutUs';
import OurService from '../Pages/WebsitePannel/OurService/OurService';
import Destination from '../Pages/WebsitePannel/Destination/Destinations';
import StateCounter from '../Pages/WebsitePannel/StatCounter/StateCounter';
import BlogList from '../Pages/WebsitePannel/Blogs/BlogList';
import AddBlog from '../Pages/WebsitePannel/Blogs/AddBlog';
import UpdateBlogs from '../Pages/WebsitePannel/Blogs/UpdateBlogs';
import Awards from '../Pages/WebsitePannel/Awards/Awards';
import Certificate from '../Pages/WebsitePannel/Certificate/Certificate';
import Associates from '../Pages/WebsitePannel/Associates/Associates';
import PhotoGallery from '../Pages/WebsitePannel/PhotoGallery/PhotoGallery';
import VideoGallery from '../Pages/WebsitePannel/VideoGallery/VideoGallery';
import Footer from '../Pages/WebsitePannel/Footer/Footer';
import AddFooter from '../Pages/WebsitePannel/Footer/AddFooter';
import EditFooter from '../Pages/WebsitePannel/Footer/EditFooter';
import JobList from '../Pages/WebsitePannel/JobList/JobList';
import AddJob from '../Pages/WebsitePannel/JobList/AddJob';
import EditJob from '../Pages/WebsitePannel/JobList/EditJob';
import AddContact from '../Pages/WebsitePannel/ContactUs/AddContact';
import EditContact from '../Pages/WebsitePannel/ContactUs/EditContact';
import TeamMember from '../Pages/WebsitePannel/TeamMembers/TeamMembers';
import AddTeamMembers from '../Pages/WebsitePannel/TeamMembers/AddTeamMembers';
import EditTeamMember from '../Pages/WebsitePannel/TeamMembers/EditTeamMember';
import CeoMessage from '../Pages/WebsitePannel/CeoMessage/CeoMessage';
import AddCeoMessage from '../Pages/WebsitePannel/CeoMessage/AddCeoMessage';
import EditCeoMessage from '../Pages/WebsitePannel/CeoMessage/EditCeoMessage';
import MissionVisionMottoList from '../Pages/WebsitePannel/MissionVisionMotto/MissionVisionMotto';
import AddMissionVisionMotto from '../Pages/WebsitePannel/MissionVisionMotto/AddMissionVisionMotto';
import EditMissionVisionMotto from '../Pages/WebsitePannel/MissionVisionMotto/EditMissionVisionMotto';
import JobRequestList from '../Pages/WebsitePannel/JobRequestList/JobRequestList';
import BlogCategory from '../Pages/WebsitePannel/BlogCategory/BlogCategoryList';
import AddPages from '../Pages/WebsitePannel/Pages/AddPages';
import PageList from '../Pages/WebsitePannel/Pages/PageList';
import EditPages from '../Pages/WebsitePannel/Pages/EditPages';
import PageDetails from '../Pages/WebsitePannel/Pages/PageDetails';
import EventCategory from '../Pages/WebsitePannel/EventCategory/EventCategoryList';
import EventList from '../Pages/WebsitePannel/Events/EventList';
import AddEvents from '../Pages/WebsitePannel/Events/AddEvents';
import UpdateEvent from '../Pages/WebsitePannel/Events/UpdateEvents';
import AddNewUser from '../Pages/UserManagement/UserListPage/AllUsers/AddNewUser';
import EditUser from '../Pages/UserManagement/UserListPage/EditUser';
import AddUserByRole from '../Pages/UserManagement/UserListPage/RoleWiseUsers/AddUserByRole';
import PackageCategoryList from '../Pages/WebsitePannel/PackageCategory/PackageCategoryList';
import PackageList from '../Pages/WebsitePannel/Package/PackageList';
import AddPackage from '../Pages/WebsitePannel/Package/AddPackage';
import UpdatePackage from '../Pages/WebsitePannel/Package/EditPackage';
import CourseCategory from '../Pages/WebsitePannel/CourseCategory/CourseCategoy';
import CourseList from '../Pages/WebsitePannel/Courses/CourseList';
import AddCourse from '../Pages/WebsitePannel/Courses/AddCourse';
import UpdateCourses from '../Pages/WebsitePannel/Courses/UpdateCourse';
import MosqueList from '../Pages/WebsitePannel/MosqueList/MosqueList';
import AddMosqueList from '../Pages/WebsitePannel/MosqueList/AddMosqueList';
import EditMosqueList from '../Pages/WebsitePannel/MosqueList/EditMosqueList';
import ContactMsg from '../Pages/WebsitePannel/ContactUs/ContactMsg';
import CampeignCategory from '../Pages/WebsitePannel/CampeignCategory/CampeignCategoryList';
import CampeignList from '../Pages/WebsitePannel/Campeigns/CampeignList';
import AddCampeign from '../Pages/WebsitePannel/Campeigns/AddCampeign';
import UpdateCampeigns from '../Pages/WebsitePannel/Campeigns/UpdateCampeign';
import Album from '../Pages/WebsitePannel/Albums/AlbumList';
import CourseRegistrationList from '../Pages/WebsitePannel/CourseRegistrationList/CourseRegistrationList';
import AudioSurah from '../Pages/WebsitePannel/audioSurah/AudioSurahList';

const router = createBrowserRouter([
    {
        index: true,
        path: '/',
        element: <Signin />,
    },
    {
        path: 'signin',
        element: <Signin />,
    },
    {
        path: 'signup',
        element: <Signup />,
    },
    {
        path: 'forget-password',
        element: <ForgetPasswordPage />,
    },
    {
        path: 'reset-password',
        element: <ResetPasswordPage />,
    },
    {
        path: 'otp-verification',
        element: <OtpVerification />,
    },
    {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: 'profile',
                element: <ProfilePage />,
            },
            {
                path: 'users',
                element: (
                    <PrivateRouter props={5}>
                        <UserListPage />
                    </PrivateRouter>
                ),
            },

            {
                path: 'users/add-user',
                element: <AddNewUser />,
            },
            {
                path: 'users/edit-user/:user_id',
                element: <EditUser />,
            },

            {
                path: 'roles',
                element: (
                    <PrivateRouter props={4}>
                        <RoleListPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'user-management/role-permissions',
                element: (
                    <PrivateRouter props={6}>
                        <RolePermissionPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'user/roles/:role_id',
                element: <UserByRoles />,
            },
            {
                path: 'user/roles/add/:role_id',
                element: <AddUserByRole />,
            },
            {
                path: 'user-management/terms-conditions',
                element: <TermsConditions />,
            },
            {
                path: 'user-management/service-category',
                element: <ServiceCategory />,
            },
            {
                path: 'module',
                // element: <ModuleListPage />,
                element: (
                    <PrivateRouter props={3}>
                        <ModuleListPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'activity',
                element: (
                    <PrivateRouter props={2}>
                        <ActivityListPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'static-content-groups',
                element: (
                    <PrivateRouter props={7}>
                        <StaticContentGroupPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'static-content',
                element: (
                    <PrivateRouter props={8}>
                        <StaticContentPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/instruments-category',
                element: (
                    <PrivateRouter props={9}>
                        <InstrumentCategoryList />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/instruments',
                element: (
                    <PrivateRouter props={10}>
                        <InstrumentsPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/room-management',
                element: (
                    <PrivateRouter props={13}>
                        <Room />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/uom',
                element: (
                    <PrivateRouter props={15}>
                        <UOMPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/gender',
                element: (
                    <PrivateRouter props={14}>
                        <GenderPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/professions',
                element: (
                    <PrivateRouter props={16}>
                        <ProfessionSettingsPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/merital-status',
                element: (
                    <PrivateRouter props={17}>
                        <MaritialStatusSettings />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/country',
                element: (
                    <PrivateRouter props={18}>
                        <Country />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/state',
                element: (
                    <PrivateRouter props={19}>
                        <State />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/city',
                element: (
                    <PrivateRouter props={20}>
                        <City />
                    </PrivateRouter>
                ),
            },
            {
                path: 'settings/system-settings',
                element: (
                    <PrivateRouter props={1}>
                        <SettingsPage />
                    </PrivateRouter>
                ),
            },
            {
                path: 'collection-label',
                element: <CollectionLabel />,
            },
            {
                path: 'settings/department',
                element: <Department />,
            },
            // Menu Management Settings
            {
                path: 'menu-groups',
                element: <MenuGroup />,
            },
            {
                path: 'menu-groups/:id',
                element: <MenuItems />,
            },
            {
                path: 'website/hero-section',
                element: <HeroSection />,
            },
            {
                path: 'website/hero-section/add',
                element: <AddHeroSection />,
            },
            {
                path: 'website/about-us',
                element: <AboutUs />,
            },
            {
                path: 'website/about-us/add',
                element: <AddAboutUs />,
            },
            {
                path: 'website/about-us/edit/:id',
                element: <UpdateAboutUs />,
            },
            {
                path: 'website/our-service',
                element: <OurService />,
            },
            {
                path: 'website/destination',
                element: <Destination />,
            },
            {
                path: 'website/stat-counter',
                element: <StateCounter />,
            },
            {
                path: 'website/blog-category',
                element: <BlogCategory />,
            },
            {
                path: 'website/blogs',
                element: <BlogList />,
            },
            {
                path: 'website/blogs/add',
                element: <AddBlog />,
            },
            {
                path: 'website/blogs/edit/:id',
                element: <UpdateBlogs />,
            },
            {
                path: 'website/awards',
                element: <Awards />,
            },
            {
                path: 'website/certificate',
                element: <Certificate />,
            },
            {
                path: 'website/associates',
                element: <Associates />,
            },
            {
                path: 'website/photo-gallery',
                element: <PhotoGallery />,
            },
            {
                path: 'website/video-gallery',
                element: <VideoGallery />,
            },
            {
                path: 'website/footer',
                element: <Footer />,
            },
            {
                path: 'website/footer/add',
                element: <AddFooter />,
            },
            {
                path: 'website/footer/edit/:id',
                element: <EditFooter />,
            },
            {
                path: 'website/job-list',
                element: <JobList />,
            },
            {
                path: 'website/job-list/add',
                element: <AddJob />,
            },
            {
                path: 'website/job-list/edit/:id',
                element: <EditJob />,
            },
            {
                path: 'website/contact-message',
                element: <ContactMsg />,
            },
            {
                path: 'website/contact-us/add',
                element: <AddContact />,
            },
            {
                path: 'website/contact-us/edit/:id',
                element: <EditContact />,
            },
            {
                path: 'website/team-members',
                element: <TeamMember />,
            },
            {
                path: 'website/team-members/add',
                element: <AddTeamMembers />,
            },
            {
                path: 'website/team-members/edit/:id',
                element: <EditTeamMember />,
            },
            {
                path: 'website/ceo-message',
                element: <CeoMessage />,
            },
            {
                path: 'website/ceo-message/add',
                element: <AddCeoMessage />,
            },
            {
                path: 'website/ceo-message/edit/:id',
                element: <EditCeoMessage />,
            },
            {
                path: 'website/mission-vision-motto',
                element: <MissionVisionMottoList />,
            },
            {
                path: 'website/mission-vision-motto/add',
                element: <AddMissionVisionMotto />,
            },
            {
                path: 'website/mission-vision-motto/edit/:id',
                element: <EditMissionVisionMotto />,
            },
            {
                path: 'website/job-request/:id',
                element: <JobRequestList />,
            },
            {
                path: 'website/pages',
                element: <PageList />,
            },
            {
                path: 'website/pages/add',
                element: <AddPages />,
            },
            {
                path: 'website/pages/edit/:id',
                element: <EditPages />,
            },
            {
                path: 'website/pages/details/:id',
                element: <PageDetails />,
            },
            {
                path: 'website/mosque-list',
                element: <MosqueList />,
            },
            {
                path: 'website/mosque-list/add',
                element: <AddMosqueList />,
            },
            {
                path: 'website/mosque-list/edit/:id',
                element: <EditMosqueList />,
            },

            {
                path: 'website/event-category',
                element: <EventCategory />,
            },
            {
                path: 'website/events',
                element: <EventList />,
            },
            {
                path: 'website/events/add',
                element: <AddEvents />,
            },
            {
                path: 'website/events/edit/:id',
                element: <UpdateEvent />,
            },
            {
                path: 'website/package-category',
                element: <PackageCategoryList />,
            },
            {
                path: 'website/packages',
                element: <PackageList />,
            },
            {
                path: 'website/packages/add',
                element: <AddPackage />,
            },
            {
                path: 'website/packages/edit/:id',
                element: <UpdatePackage />,
            },
            {
                path: 'website/course-category',
                element: <CourseCategory />,
            },
            {
                path: 'website/courses',
                element: <CourseList />,
            },
            {
                path: 'website/courses/add',
                element: <AddCourse />,
            },
            {
                path: 'website/courses/edit/:id',
                element: <UpdateCourses />,
            },
            {
                path: 'website/courses-registration',
                element: <CourseRegistrationList />,
            },
            {
                path: 'website/campeign-category',
                element: <CampeignCategory />,
            },
            {
                path: 'website/campeigns',
                element: <CampeignList />,
            },
            {
                path: 'website/campeigns/add',
                element: <AddCampeign />,
            },
            {
                path: 'website/campeigns/edit/:id',
                element: <UpdateCampeigns />,
            },
            {
                path: 'website/album',
                element: <Album />,
            },
            {
                path: 'website/audio-surah',
                element: <AudioSurah />,
            },
        ],
    },
]);

export default router;
