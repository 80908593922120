import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import useFetch from '../../../hooks/useFetch';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import PackageFormContainer from '../../../components/Forms/Websites/Package/PackageFormContainer';

const UpdatePackage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { result, isLoading } = useFetch(() => request.getOne('package-management', id));
    console.log(result);

    const UpdateAboutUsHandler = (formValue) =>
        request.updateOne('package-management', id, formValue, () => {
            navigate('/dashboard/website/packages');
        });
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Package'>
                <PackageFormContainer apiHandler={UpdateAboutUsHandler} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdatePackage;
