import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { format } from 'date-fns';
import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import DeleteModal from '../../../components/Modal/DeleteModal';
import DetailsButton from '../../../components/Button/DetailsButton';
import EventDescriptionShowModal from '../../../components/Website/Events/EventsDescriptionShowModal';
import eventDateType from '../../../data/eventDateType';

const EventList = () => {
    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('events'));
    const navigate = useNavigate();
    console.log(result);

    const [modalOpen, setModalOpen] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const columns = [
        {
            name: 'Title',
            wrap: true,
            center: 'true',
            selector: (row) => row?.title,
        },
        {
            name: 'Event Category',
            wrap: true,
            center: 'true',
            selector: (row) => row?.event_category?.map((item) => item?.title).join(', '),
        },
        {
            name: 'Event Images',
            wrap: true,
            width: '150px',
            cell: (row) => (
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.event_img}`}
                    alt='side Img'
                />
            ),
        },
        {
            name: 'Date Type',
            wrap: true,
            center: 'true',
            selector: (row) => row.date_type,
        },

        {
            name: 'Event Date',
            wrap: true,
            center: 'true',
            selector: (row) =>
                format(
                    new Date(row.event_date),
                    eventDateType.find((item) => item.value === row?.date_type).dateFormat ||
                        'MMMM d, yyyy h:mm aa'
                ),
        },

        {
            name: 'Event Date Time',
            wrap: true,
            center: 'true',
            selector: (row) => row?.event_date_time,
        },
        // {
        //   name: "Description",
        //   wrap: true,
        //   center: "true",
        //   cell: (row) => (
        //     <div dangerouslySetInnerHTML={{ __html: row?.long_description }} />
        //   ),
        // },

        {
            name: 'Status',
            center: 'true',
            selector: (row) => (row?.status === 1 ? 'Active' : 'InActive'),
        },
        {
            name: 'Action',
            center: 'true',
            cell: (row) => (
                <div>
                    <DetailsButton
                        onClick={() => {
                            setModalOpen('details');
                            setSelectedItem(row);
                        }}
                    />
                    <EditButton
                        onClick={() => navigate(`/dashboard/website/events/edit/${row?.id}`)}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen('delete');
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    // delete function

    const eventDeleteHandler = () =>
        request.delete('events', selectedItem?.id, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Event List'
            buttonTitle='Add New Events'
            addButtonHandler={() => navigate('/dashboard/website/events/add')}
        >
            <ReactDataTable columns={columns} data={result} pending={isLoading} />

            {/* delete about us */}

            {modalOpen === 'delete' && (
                <DeleteModal
                    isOpen={modalOpen === 'delete'}
                    onClose={() => setModalOpen('')}
                    handleDelete={eventDeleteHandler}
                />
            )}

            {/* blog details modal  */}

            {modalOpen === 'details' && (
                <EventDescriptionShowModal
                    isOpen={modalOpen === 'details'}
                    onClose={() => {
                        setModalOpen('');
                        setSelectedItem('');
                    }}
                    eventData={selectedItem}
                />
            )}
        </CardLayout>
    );
};

export default EventList;
