import React from 'react';
import { Modal } from 'react-bootstrap';

const EventDescriptionShowModal = ({ eventData, isOpen, onClose }) => (
    <Modal show={isOpen} onHide={onClose} size='lg'>
        <Modal.Body>
            <div className='mb-4'>
                <h5> Short Description </h5>
                {eventData.short_description}
            </div>

            <div>
                <h5> Full Description </h5>

                <div dangerouslySetInnerHTML={{ __html: eventData.description }} />
            </div>
        </Modal.Body>
    </Modal>
);

export default EventDescriptionShowModal;
