import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import MosqueListFormContainer from '../../../components/Forms/Websites/MosqueListForm/MosqueLitFormContainer';

const EditMosqueList = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { result, isLoading } = useFetch(() => request.getOne('mosque-list', id), id);

    const updateMosqueList = (data) =>
        request.updateOne('mosque-list', id, data, () => {
            navigate('/dashboard/website/mosque-list');
        });
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Edit Mosque Times'>
                <MosqueListFormContainer editData={result} apiHandler={updateMosqueList} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default EditMosqueList;
