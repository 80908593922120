import React from 'react';
import { Modal } from 'react-bootstrap';

const CampeignsDetailsModal = ({ campeignData, isOpen, onClose }) => (
    <Modal show={isOpen} onHide={onClose} size='lg'>
        <Modal.Body>
            <div>
                <h5>Short Description :</h5>

                <p> {campeignData.short_description} </p>
            </div>
            <div>
                <h5> Full Description </h5>

                <div dangerouslySetInnerHTML={{ __html: campeignData.long_description }} />
            </div>
        </Modal.Body>
    </Modal>
);

export default CampeignsDetailsModal;
