import { useFormikContext } from 'formik';
import React from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import CrudFormLayout from '../../../../Layout/CrudFormLayout';
import ReactSelect from '../../../../ReactSelect/ReactSelect';
import FormLabel from '../../../../Text/FormLabel';
import MenuItemForms from './MenuItemForms';

const MenuItemsFormContainer = ({
    apiHandler,
    menuData,
    clickedMenuData,
    onClose,
    deleteMenuHandler,
}) => {
    const { id } = useParams();

    const menuSchema = Yup.object().shape({
        menu_level: Yup.string().required('Menu level is required'),
        url_slug: Yup.string(),
        menu_order: Yup.number()
            .integer()
            .positive()
            .required('Menu Order is Required')
            .typeError('Must be integer'),
        menu_icon: Yup.string(),
        parent_menu_id: Yup.number()
            .nullable()
            .transform((value) => (Number.isNaN(value) ? null : value)),
        menu_group_id: Yup.number().integer().positive().required('Menu group ID is required'),
    });

    const initialValues = {
        menu_level: clickedMenuData ? clickedMenuData?.menu_level : '',
        url_slug: clickedMenuData ? clickedMenuData?.url_slug : '',
        menu_order: clickedMenuData ? clickedMenuData?.menu_order : '',
        menu_icon: clickedMenuData ? clickedMenuData?.menu_icon : '',
        parent_menu_id: clickedMenuData ? clickedMenuData?.parent_menu_id : '',
        menu_group_id: clickedMenuData ? clickedMenuData?.menu_group_id : id,
    };

    const handleSubmit = async (formData, { resetForm }) => {
        // console.log(formData);

        const validateData = await menuSchema.validate(formData);

        if (apiHandler) {
            return apiHandler(validateData, resetForm);
        }

        return null;
    };

    return (
        <Row>
            <CrudFormLayout
                initialValues={initialValues}
                apiHandler={handleSubmit}
                noButton
                validationSchema={menuSchema}
            >
                <MenuItemForms>
                    <ParentMenuSelect menuData={menuData} />
                </MenuItemForms>

                {/* Button part  */}

                {clickedMenuData ? (
                    <UpdateDeleteButton onClose={onClose} deleteMenuHandler={deleteMenuHandler} />
                ) : (
                    <SubmitButton />
                )}
            </CrudFormLayout>
        </Row>
    );
};

export default MenuItemsFormContainer;

const ParentMenuSelect = ({ menuData }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <Form.Group>
            <FormLabel title='Parent Menu' />

            <ReactSelect
                data={[{ menu_level: 'None', id: '' }, ...menuData]}
                labelName='menu_level'
                valueName='id'
                value={values?.parent_menu_id}
                onChange={(val) => setFieldValue('parent_menu_id', val)}
                placeholder='NONE'
            />
        </Form.Group>
    );
};

const SubmitButton = () => {
    const { dirty } = useFormikContext();

    return (
        <div className='mt-3'>
            <Button variant='teal' size='sm' type='submit' disabled={!dirty}>
                Add New Menu
            </Button>
        </div>
    );
};

const UpdateDeleteButton = ({ onClose, deleteMenuHandler }) => (
    <div className='d-flex justify-content-between mt-4'>
        <Button type='button' variant='danger' onClick={() => deleteMenuHandler()}>
            Delete
        </Button>

        <div>
            <Button type='button' variant='secondary' className='me-2' onClick={() => onClose()}>
                Close
            </Button>
            <Button type='submit' variant='teal'>
                Update
            </Button>
        </div>
    </div>
);
