import { FieldArray, useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import durationData from '../../../../data/durationData';
import useFetch from '../../../../hooks/useFetch';
import request from '../../../../request/request';
import ReactInput from '../../../InputList/ReactInput/ReactInput';
import ReactSelect from '../../../ReactSelect/ReactSelect';
import ErrorMsg from '../../../Text/ErrorMsg';
import FormLabel from '../../../Text/FormLabel';
import FeaturesForm from './FeaturesForm';

const PackageForm = () => {
    const { result: categories } = useFetch(() => request.getAll('package-category?status=1'));
    const { values, setFieldValue } = useFormikContext();

    return (
        <div>
            <Form.Group as={Row}>
                <Col sm={12} md={4}>
                    <FormLabel title='Package Category' required />
                </Col>
                <Col sm={12} md={8}>
                    <ReactSelect
                        data={categories}
                        value={values.package_cat_id}
                        onChange={(val) => setFieldValue('package_cat_id', val)}
                    />
                    <ErrorMsg name='package_cat_id' />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={12} md={4}>
                    <FormLabel title='Package Name' required />
                </Col>
                <Col sm={12} md={8}>
                    <ReactInput name='name' placeholder='Package Name' />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={12} md={4}>
                    <FormLabel title='Features' required />
                </Col>
                <Col sm={12} md={8}>
                    <FieldArray
                        name='features'
                        render={(arrayHelpers) => (
                            <FeaturesForm remove={arrayHelpers.remove} add={arrayHelpers.push} />
                        )}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={12} md={4}>
                    <FormLabel title='Price' required />
                </Col>
                <Col sm={12} md={8}>
                    <ReactInput name='price' placeholder='Package Name' />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={12} md={4}>
                    <FormLabel title='Duration' required />
                </Col>
                <Col sm={12} md={8}>
                    <ReactSelect
                        data={durationData}
                        placeholder='Select Duration'
                        labelName='title'
                        valueName='value'
                        value={values.duration}
                        onChange={(val) => setFieldValue('duration', val)}
                    />
                    <ErrorMsg name='duration' />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={12} md={4}>
                    <FormLabel title='Package Discount (in %)' required />
                </Col>
                <Col sm={12} md={8}>
                    <ReactInput
                        name='package_discount'
                        placeholder='Package Discount'
                        type='number'
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={12} md={4}>
                    <FormLabel title='Color' required />
                </Col>
                <Col sm={12} md={8}>
                    <ReactInput name='color' placeholder='Package Color' type='color' />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mt-3'>
                <Col sm={12} md={4}>
                    <FormLabel title='Status' required />
                </Col>
                <Col sm={12} md={8}>
                    <ReactInput name='status' label='Active' type='checkbox' />
                </Col>
            </Form.Group>
        </div>
    );
};

export default PackageForm;
