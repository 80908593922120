import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa6';
import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import DeleteModal from '../../../components/Modal/DeleteModal';

const JobList = () => {
    const navigate = useNavigate();

    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('job-list'));

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    console.log(result);

    const columns = [
        {
            name: 'Title',
            wrap: true,
            center: 'true',
            selector: (row) => row?.title,
        },
        {
            name: 'Company Name',
            wrap: true,
            center: 'true',
            selector: (row) => row?.company_name,
        },
        {
            name: 'Job Type',
            wrap: true,
            center: 'true',
            selector: (row) => row?.job_type,
        },
        {
            name: 'Category',
            wrap: true,
            center: 'true',
            selector: (row) => row?.category,
        },
        {
            name: 'Job Tag',
            wrap: true,
            center: 'true',
            selector: (row) => row?.job_tag,
        },
        {
            name: 'Location',
            wrap: true,
            center: 'true',
            selector: (row) => row?.locationInfo?.name,
        },
        {
            name: 'Offerd Salery',
            wrap: true,
            center: 'true',
            selector: (row) => row?.offered_salary,
        },
        {
            name: 'CTC',
            wrap: true,
            center: 'true',
            selector: (row) => row?.ctc,
        },

        {
            name: 'Experience',
            wrap: true,
            center: 'true',
            selector: (row) => row?.experience,
        },
        {
            name: 'Industry',
            wrap: true,
            center: 'true',
            selector: (row) => row?.industry,
        },
        {
            name: 'Application End',
            wrap: true,
            center: 'true',
            selector: (row) => row?.application_end,
        },
        {
            name: 'Action',
            wrap: true,
            center: 'true',
            width: '200px',
            cell: (row) => (
                <div>
                    <Button
                        className='me-3'
                        variant='warning'
                        size='sm'
                        onClick={() => navigate(`/dashboard/job-request/${row?.id}`)}
                    >
                        <FaEye />
                    </Button>

                    <EditButton onClick={() => navigate(`edit/${row?.id}`)} />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen(true);
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const deleteJobDataHandler = () =>
        request.delete('job-list', selectedItem.id, () => {
            setModalOpen(false);
            setSelectedItem(null);
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Job List'
            buttonTitle='Add New Job'
            addButtonHandler={() => navigate('add')}
        >
            <ReactDataTable data={result} columns={columns} pending={isLoading} />

            {isModalOpen && (
                <DeleteModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedItem(null);
                    }}
                    handleDelete={deleteJobDataHandler}
                />
            )}
        </CardLayout>
    );
};

export default JobList;
