import { useState } from 'react';
import DOMPurify from 'dompurify';
import CardLayout from '../../../components/Layout/CardLayout';
import ReactDataTable from '../../../components/ReactDataTable/ReactDataTable';
import useFetch from '../../../hooks/useFetch';
import request from '../../../request/request';
import ModalLayout from '../../../components/Layout/ModalLayout';
import OurServiceForm from '../../../components/Forms/OurServices/OurServiceForm';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import EditButton from '../../../components/Button/EditButton';
import DeleteButton from '../../../components/Button/DeleteButton';
import DeleteModal from '../../../components/Modal/DeleteModal';
import DestinationForm from '../../../components/Forms/Websites/Destination/DestinationForm';
import StatCounterForm from '../../../components/Forms/StatCounter/StatCounterForm';

const Destination = () => {
    const [modalOpen, setModalOpen] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const { result, isLoading, setRefresh } = useFetch(() => request.getAll('stat-counter'));

    const columns = [
        {
            name: 'Title',
            center: 'true',
            selector: (row) => row?.title,
        },
        {
            name: 'Icon',
            width: '100px',
            cell: (row) => {
                <img
                    style={{ width: '100%' }}
                    src={`${process.env.REACT_APP_UPLOAD_URL}/website/${row?.icon_img}`}
                    alt='side Img'
                />;
            },
        },
        {
            name: 'Count Number',
            wrap: true,
            center: 'true',
            selector: (row) => row?.count_number,
        },
        {
            name: 'Status',
            center: 'true',
            selector: (row) => (row?.status === 1 ? 'Active' : 'Inactive'),
        },
        {
            name: 'Active',
            center: 'true',
            cell: (row) => (
                <div>
                    <EditButton
                        onClick={() => {
                            setModalOpen('update');
                            setSelectedItem(row);
                        }}
                    />
                    <DeleteButton
                        onClick={() => {
                            setModalOpen('delete');
                            setSelectedItem(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    // create new function

    const createStateCounterHandler = (formValue) => {
        request.create('stat-counter', formValue, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });
    };

    // update

    const updateStatHanlerHandler = (formValue) => {
        request.updateOne('stat-counter', selectedItem?.id, formValue, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });
    };

    // delete

    const deleteServiceHandler = () =>
        request.delete('stat-counter', selectedItem?.id, () => {
            setModalOpen('');
            setRefresh((prev) => !prev);
        });

    return (
        <CardLayout
            title='Destination List'
            buttonTitle='Add new Destination'
            addButtonHandler={() => setModalOpen('create')}
        >
            <ReactDataTable data={result} columns={columns} pending={isLoading} />
            {/*= ============== create ===================== */}

            {modalOpen === 'create' && (
                <ModalLayout
                    isOpen={modalOpen === 'create'}
                    onClose={() => setModalOpen('')}
                    title='Create New Destination'
                    size='lg'
                >
                    <StatCounterForm apiHandler={createStateCounterHandler} />
                </ModalLayout>
            )}

            {/* ======================= update ========================== */}

            {modalOpen === 'update' && (
                <ModalLayout
                    isOpen={modalOpen === 'update'}
                    onClose={() => setModalOpen('')}
                    title='Update New Destination'
                    size='lg'
                >
                    <StatCounterForm apiHandler={updateStatHanlerHandler} editData={selectedItem} />
                </ModalLayout>
            )}

            {/* ======================= delete ========================== */}

            {modalOpen === 'delete' && (
                <DeleteModal
                    isOpen={modalOpen === 'delete'}
                    onClose={() => setModalOpen('')}
                    handleDelete={deleteServiceHandler}
                />
            )}
        </CardLayout>
    );
};

export default Destination;
