import { useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../../components/Layout/CardLayout';
import request from '../../../request/request';
import useFetch from '../../../hooks/useFetch';
import LoaderLayout from '../../../components/Loader/LoaderLayout';
import { formDataConverter } from '../../../utils/necessaryFunctions';
import EventFormContainer from '../../../components/Forms/Websites/Events/EventsFormContainer';

const UpdateEvent = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { result, isLoading } = useFetch(() => request.getOne('events', id));

    const UpdateAboutUsHandler = (formValue) => {
        const formData = formDataConverter(formValue);

        return request.updateOne('events', id, formData, () => {
            navigate('/dashboard/website/events');
        });
    };
    return (
        <LoaderLayout isPending={isLoading}>
            <CardLayout title='Update Events'>
                <EventFormContainer apiHandler={UpdateAboutUsHandler} editData={result} />
            </CardLayout>
        </LoaderLayout>
    );
};

export default UpdateEvent;
